import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h2: {
      fontSize: {
        base: '20px',
        md: '22px',
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 600 : 'normal',
    },
    successSt: {
      fontSize: {
        base: '16px',
        md: '18px',
      },
      fontFamily: lang ? config.langFont[lang] : 'FedraSansStd-book',
      fontWeight: 'normal',
    },
    regSt: {
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 600 : 'normal',
    },
    errorTitle: {
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
    },
    errorSt: {
      fontFamily: lang ? config.langFont[lang] : fontFamily,
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const ThankYouSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({ lang: 'ar' }),
  id: commonLangStyle({ lang: 'id' }),
};

export default ThankYouSt;
