import { useEffect, useState } from 'react';

export const PageTypes = Object.freeze({
    DEFAULT: 'default',
    GIFT: 'gift'
});

export const usePageType = () => {
    const [pageType, setPageType] = useState(PageTypes.DEFAULT);

    useEffect(() => {
        if (window.location.pathname.indexOf('gift') !== -1) {
            setPageType(PageTypes.GIFT);
        }
    }, []);

    return {
        pageType,
        isGiftPage: pageType === PageTypes.GIFT,
        isDefaultPage: pageType === PageTypes.DEFAULT
    };
};
