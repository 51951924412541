import config from '@config';
import merge from 'lodash/merge';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h3: {
      fontSize: {
        base: '18px',
        md: '20px',
        xl: '26px',
      },
      fontFamily: config.langFont[lang],
      fontWeight: '400',
    },
  };
  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const QuotesSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({
    lang: 'zh-tw',
  }),
  zh: commonLangStyle({
    lang: 'zh',
  }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({
    lang: 'ta',
    h3: {
      fontSize: {
        base: '18px',
        md: '20px',
        xl: '28px',
      },
      fontWeight: '400',
    },
  }),
  ar: commonLangStyle({
    lang: 'ar',
    h3: {
      fontFamily: "'Almarai', sans-serif",
      fontWeight: 400,
    },
    quote: {
      fontFamily: "'Almarai', sans-serif",
      fontWeight: 400,
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default QuotesSt;
