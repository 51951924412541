import config from '@config';

const chineseFont = "'Noto Sans TC', sans-serif";
const tamilFont = "'Anek Tamil', sans-serif";
const fontFamily = 'FedraSansStd-medium';
const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    SLBlueText: {
      fontSize: {
        base: '20px',
        md: '24px',
      },
      color: '#12166F',
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontStyle: 'normal',
      fontWeight: isIndian ? '600' : 'normal',
      lineHeight: isIndian
        ? { base: '27px', md: '30px' }
        : { base: '22px', md: '24.24px' },
      letterSpacing: '-0.12px',
    },
    withSadhguru: {
      fontSize: {
        base: '20px',
        md: '40px',
      },
      color: '#3B444B',
      fontFamily: isIndian ? config.langFont[lang] : 'FedraSansStd-book',
      fontStyle: 'normal',
      fontWeight: isIndian ? '600' : 'normal',
      lineHeight: { base: '22px', md: '32px' },
      margin: '9px 0',
      my: '0',
    },

    SLBlueTextV3: {
      fontSize: {
        base: '32px',
        md: '52px',
      },
      color: '#3B444B',
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontStyle: 'normal',
      fontWeight: isIndian ? '600' : 'normal',
      lineHeight: { base: '38px', md: '64px' },
      letterSpacing: '-2px',
    },
    YoSLBlueTextV3: {
      fontSize: {
        base: '26px',
        md: '52px',
      },
      color: '#3B444B',
      fontFamily: isIndian ? 'FedraSansStd-medium' : fontFamily,
      fontStyle: 'normal',
      fontWeight: isIndian ? '600' : 'normal',
      lineHeight: { base: '38px', md: '64px' },
    },
    ReInHeading: {
      fontSize: {
        base: '20px',
        md: '24px',
        lg: '36px',
      },
      color: isIndian ? { base: '#52483D', md: '#12166F' } : '#12166F',
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontStyle: 'normal',
      fontWeight: isIndian ? { base: '400', md: 600 } : 'normal',
      lineHeight: isIndian
        ? { base: '30px', md: '34px', lg: '39.6px' }
        : { base: '30px', md: '34px', lg: '39.6px' },
    },
    GrayText: {
      fontSize: isIndian
        ? { base: '20px', md: '22px' }
        : {
            base: '18px',
            md: '22px',
          },
      color: isIndian ? { base: '#12166F', md: '#52483D' } : '#52483D',
      fontFamily: lang ? config.langFont[lang] : fontFamily,
      fontStyle: 'normal',
      fontWeight: isIndian ? { base: '600', md: 'normal' } : 'normal',
      lineHeight: isIndian
        ? { base: '20px', md: '31.24px' }
        : { base: '25.56px', md: '31.24px' },
    },
  };
  return { ...styleObj, ...props };
};

const ResearchIntroSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({
    lang: 'hi',
    ReInHeading: {
      fontSize: {
        base: '20px',
        md: '24px',
        lg: '36px',
      },
      color: '#12166F',
      fontWeight: '700',
      lineHeight: { base: '26px', md: '26px', lg: '39.6px' },
    },
    GrayText: {
      fontSize: { base: '18px', md: '22px' },
      fontWeight: '400',
      color: '#52483D',
    },
    HindiAnimation: {
      margin: { base: '65px  0 0 0', md: '20px  0 0 0', lg: '-6px 0 0 30px' },
      fontWeight: '700',
      display: 'flex',
      alignItems: { base: 'center', lg: 'flex-start' },
      lineHeight: 'normal',
      width: { base: '100%', md: '50%' },
    },
  }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({
    lang: 'ar',
    SLBlueText: {
      fontSize: {
        base: '20px',
        md: '24px',
        lg: '36px',
      },
      color: '#12166F',
      fontFamily: "'Almarai', sans-serif",
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: { base: '27px', md: '30px' },
      letterSpacing: '-0.12px',
      textAlign: 'right',
    },
    ReInHeading: {
      fontSize: {
        base: '20px',
        md: '24px',
        lg: '36px',
      },
      color: '#12166F',
      fontFamily: "'Almarai', sans-serif",
      fontWeight: '700',
      lineHeight: { base: '26px', md: '26px', lg: '39.6px' },
      textAlign: 'right',
    },
    GrayText: {
      fontFamily: "'Almarai', sans-serif",
      fontSize: { base: '18px', md: '22px' },
      fontWeight: '400',
      color: '#52483D',
      textAlign: 'right',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default ResearchIntroSt;
