import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h2: {
      fontSize: {
        base: 32,
        md: 54,
        lg: 64,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },

    subTitle: {
      fontSize: {
        base: 20,
        md: 28,
        lg: 32,
      },
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
      textAlign: 'left',
    },

    description: {
      fontSize: {
        base: 16,
        md: 18,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
    p: {
      fontSize: {
        base: 16,
        md: 16,
      },
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
    },
    userDescription: {
      fontSize: {
        base: 16,
        md: 18,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },

    details: {
      fontSize: {
        base: 16,
        md: 18,
      },
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
    },
    additionalDetails: {
      fontSize: {
        base: 16,
        md: 18,
      },
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
    },

    linkReadMore: {
      fontSize: {
        base: 16,
        md: 16,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
    countNumber: {
      fontSize: {
        base: 16,
        md: 22,
        lg: 30,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
    modalDes: {
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 400 : 'normal',
      fontSize: { base: '16px', md: '18px' },
      lineHeight: { base: '22.4px', md: '27px' },
    },
    modalName: {
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 600 : 'normal',
      fontSize: { base: '16px', md: '22px' },
      lineHeight: { base: '22.4px', md: '21.34px' },
    },
    modalDetail: {
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 550 : '450',
      lineHeight: { base: '17.64px', md: '17.6px' },
      fontSize: { base: '14px', md: '16px' },
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const Stories = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({
    lang: 'ar',

    h2: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: { base: 'right', lg: 'left' },
    },

    subTitle: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: { base: 'right', lg: 'left' },
    },

    description: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    p: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    userDescription: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },

    details: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    additionalDetails: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },

    linkReadMore: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
      display: 'block',
    },

    modalDes: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    modalName: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    modalDetail: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
      justifyContent: 'flex-end',
      paddingRight: { base: '10px', md: '30px' },
      display: 'block',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default Stories;
