import { createContext, useContext, useMemo, useState } from 'react';

const EnrollContext = createContext();

const EnrollContextProvider = ({ children }) => {
  const [contextData, setContextData] = useState(null);

  const value = useMemo(
    () => ({
      contextData,
      setContextData,
    }),
    [
      contextData,
      setContextData,
    ]
  );
  return <EnrollContext.Provider value={value}>{children}</EnrollContext.Provider>;
};

export const useEnrollContext = () => {
  return useContext(EnrollContext);
};

export { EnrollContext, EnrollContextProvider };
