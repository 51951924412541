import config from '@config';

export function getApiUrl() {
  return config.NEXT_PUBLIC_USPRS_API_URL;
}

export function getCallbackUrl({ country, language, region, url }) {
  return (
    window.location.protocol +
    '//' +
    window.location.host +
    `/${region}/${language}` +
    url +
    '?country=' +
    encodeURIComponent(country || 'no-country')
  );
}

export function formPost(url, data) {
  const form = document.createElement('form');
  // form.style = 'display: none';
  form.method = 'post';
  form.action = url;

  Object.keys(data).forEach(key => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
    // console.log('key', key, data[key]);
  });

  document.body.appendChild(form);
  form.submit();
}
