import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h1: {
      fontSize: {
        base: '16px',
        md: '32px',
      },
      fontFamily: {
        base: lang ? config.langFont[lang] : fontFamily,
        md: lang ? config.langFont[lang] : fontFamily,
      },
      fontWeight: {
        base: lang ? '800' : 'normal',
        md: '100',
      },
    },
    stText: {
      fontFamily: lang ? config.langFont[lang] : fontFamily,
      fontSize: {
        base: '16px',
        md: '18px',
      },
      lineHeight: isIndian
        ? { base: '1.2em', md: '1.25em', lg: '1.4em' }
        : { base: '1.3125em', md: '1.375em', lg: '1.56em' },
      mt: isIndian
        ? { base: '25px', md: '30px' }
        : // : { base: '28.46px', lg: '20px' }, ui update
          { base: '28.46px', lg: '50px' },
    },
    uiChangeStText: {
      fontFamily: lang ? config.langFont[lang] : fontFamily,
      fontSize: {
        base: '16px',
        md: '18px',
      },
      lineHeight: isIndian
        ? { base: '1.2em', md: '1.25em', lg: '1.4em' }
        : { base: '1.3125em', md: '1.375em', lg: '1.56em' },
      mt: isIndian
        ? { base: '25px', md: '30px' }
        : { base: '28.46px', lg: '20px' },
      // : { base: '28.46px', lg: '50px' }
    },
    cardHeading: {
      fontSize: {
        base: '20px',
        md: '24px',
        lg: '26px',
      },
      lineHeight: '1.25em',
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: 500,
      color: '#F37021',
    },
    buttonText: {
      fontSize: { base: '20px', md: '18px', lg: '22px' },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      mt: isIndian
        ? { base: '0', md: '25px', lg: '30px' }
        : // : { base: '0', md: '30px', lg: '40px' }, ui update
          { base: '0', md: '30px', lg: '50px' },
    },
    uiChangeButtonText: {
      fontSize: { base: '20px', md: '18px', lg: '22px' },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      mt: isIndian
        ? { base: '0', md: '25px', lg: '30px' }
        : // : { base: '0', md: '30px', lg: '40px' }, ui update
          { base: '0', md: '30px', lg: '40px' },
    },

    loginButton: {
      fontFamily: `${isIndian ? config.langFont[lang] : fontFamily}`,
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const PageSectionV5St = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({
    lang: 'hi',
    buttonSt: {
      lineHeight: '3rem',
    },
  }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({
    lang: 'ar',
    loginButton: {
      fontFamily: "'Almarai', sans-serif",
      overflow: 'inherit',
    },
    buttonText: {
      fontFamily: "'Almarai', sans-serif",
      fontWeight: '700',
      fontSize: { base: '20px', md: '22px' },
    },
    stText: {
      fontFamily: "'Almarai', sans-serif",
      fontWeight: '400',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};
export default PageSectionV5St;
