/* Built In Imports */

/* External Imports */
import { Box, Divider, Icon } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */
import { searchByFieldId } from '@components/Utility/Shared/SharedService';

/**
 *
 * @param {Object} prgData
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @param {Object} pageData
 * @returns ProgramFee
 */
const YouthOfferMsgs = ({
  sectionContent,
  region,
  lang,
  context,
  pageData,
}) => {
  // }
  return (
    <>
      {parseInt(context?.age) >= 15 && parseInt(context?.age) <= 24 && (
        <Box py={{ base: '40px', lg: '60px' }}>
          <Box
            bg="#fff"
            p={{ base: '8px', lg: '20px' }}
            maxW="1030px"
            w={{ base: '85%', xl: '100%' }}
            mx="auto"
          >
            <Box
              w={{ base: '90%', md: '50%', xl: '32%' }}
              display="flex"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              minH={{ base: '150px', lg: '200px' }}
              mx="auto"
            >
              {searchByFieldId(sectionContent, 'yo-congrats-head')?.body
                .value && (
                <Box display="flex" gridGap="8px" alignItems="center" mb="10px">
                  <Icon w="35px" h="50px">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.75 11.875L13.125 18.75L10.625 16.25M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                        stroke="#00A171"
                        stroke-width="2.5"
                        stroke-linecap="square"
                      />
                    </svg>
                  </Icon>
                  <StructuredTextParser
                    str={render(
                      searchByFieldId(sectionContent, 'yo-congrats-head').body
                        .value
                    )}
                    color="#00A171"
                    fontSize={{ base: '20px', lg: '24px' }}
                    display="flex"
                    justifyContent="center"
                    fontFamily="FedraSansStd-medium"
                    fontWeight="normal"
                    alignItems="center"
                    mt="0"
                  />
                </Box>
              )}
              {searchByFieldId(sectionContent, 'yo-congrats-text')?.body
                .value && (
                <StructuredTextParser
                  str={render(
                    searchByFieldId(sectionContent, 'yo-congrats-text')?.body
                      .value
                  )}
                  color="#433F3C"
                  fontSize={{ base: '16px', lg: '18px' }}
                  display="flex"
                  justifyContent="center"
                  fontFamily="'FedraSansStd-medium'"
                  fontWeight="normal"
                  alignItems="center"
                  textAlign="center"
                  mt="0"
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
      {parseInt(context?.age) > 24 && (
        <Box py={{ base: '40px', lg: '60px' }}>
          <Box
            bg="#fff"
            p={{ base: '25px 10px', lg: '60px' }}
            maxW="1030px"
            w={{ base: '85%', xl: '100%' }}
            mx="auto"
          >
            <Box
              w={{ base: '100%', md: '90%', xl: '75%' }}
              display="flex"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              mx="auto"
              px={{ base: '0', md: '5%' }}
            >
              {searchByFieldId(sectionContent, 'yo-failed-head').body.value && (
                <StructuredTextParser
                  str={render(
                    searchByFieldId(sectionContent, 'yo-failed-head').body.value
                  )}
                  color="#C74D4D"
                  fontSize={{ base: '16px', lg: '18px' }}
                  display="flex"
                  justifyContent="center"
                  fontFamily="'FedraSansStd-medium'"
                  fontWeight="normal"
                  alignItems="center"
                  textAlign="center"
                  mt="0"
                />
              )}
              {searchByFieldId(sectionContent, 'yo-failed-text1').body
                .value && (
                <>
                  <StructuredTextParser
                    str={render(
                      searchByFieldId(sectionContent, 'yo-failed-text1').body
                        .value
                    )}
                    color="#444444"
                    fontSize="16px"
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="start"
                    fontFamily="'FedraSansStd-medium'"
                    fontWeight="normal"
                    alignItems="start"
                    textAlign={{ base: 'center', md: 'start' }}
                    pl="2%"
                    className="black-text"
                  />
                  <style>
                    {`
                      .black-text a {
                        text-decoration: underline;
                      }
                    `}
                  </style>
                </>
              )}
              <Divider orientation="horizontal" pt="20px" />
              <Box maxW="480px">
                {searchByFieldId(sectionContent, 'yo-failed-text-2').body
                  .value && (
                  <StructuredTextParser
                    str={render(
                      searchByFieldId(sectionContent, 'yo-failed-text-2').body
                        .value
                    )}
                    color="#3A3A3A"
                    fontSize={{ base: '16px', lg: '18px' }}
                    justifyContent="center"
                    fontFamily="'FedraSansStd-book'"
                    fontWeight="normal"
                    alignItems="center"
                    textAlign="center"
                  />
                )}
              </Box>
            </Box>

            {/* {sectionContent} */}
            <SimpleButton
              background="#3652B8"
              textAlign="center"
              mx="auto"
              borderRadius="130px !important"
              style={sectionContent[17]}
              mt={{ base: '20px', md: '30px' }}
              mb="0"
              p="10px 29px"
              lang={lang}
              region={region}
            />
          </Box>
        </Box>
      )}
      {parseInt(context?.age) < 15 && (
        <Box py={{ base: '40px', lg: '60px' }}>
          <Box
            bg="#fff"
            p={{ base: '25px', lg: '60px' }}
            maxW="1030px"
            w={{ base: '85%', xl: '100%' }}
            mx="auto"
          >
            <Box
              w={{ base: '100%', md: '90%', xl: '60%' }}
              display="flex"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              mx="auto"
            >
              {searchByFieldId(sectionContent, 'yo-failed-minor-head').body
                .value && (
                <StructuredTextParser
                  str={render(
                    searchByFieldId(sectionContent, 'yo-failed-minor-head').body
                      .value
                  )}
                  color="#C74D4D"
                  fontSize={{ base: '16px', lg: '18px' }}
                  display="flex"
                  justifyContent="center"
                  fontFamily="'FedraSansStd-medium'"
                  fontWeight="normal"
                  alignItems="center"
                  textAlign="center"
                  mt="0"
                />
              )}
              {searchByFieldId(sectionContent, 'yo-failed-minor-text').body
                .value && (
                <StructuredTextParser
                  str={render(
                    searchByFieldId(sectionContent, 'yo-failed-minor-text').body
                      .value
                  )}
                  color="#444444"
                  fontSize={{ base: '16px', lg: '18px' }}
                  display="flex"
                  justifyContent="center"
                  fontFamily="'FedraSansStd-medium'"
                  fontWeight="normal"
                  alignItems="center"
                  textAlign="center"
                />
              )}
            </Box>
          </Box>
        </Box>
      )}{' '}
    </>
  );
};

export default YouthOfferMsgs;
