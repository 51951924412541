/* Built In Imports */
/* External Imports */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

/* Internal Imports */
/* Components */
import { LanguageContext } from '@ieo/Components/Form/ieo/context/languageContext';
import { RegionContext } from '@ieo/Components/Form/ieo/context/regionContext';
import { SectionContentContext } from '@ieo/Components/Form/ieo/context/sectionContentContext';
import { IeoRegistrationForm } from '@ieo/Components/Form/ieo/ieoRegistrationForm';
import { TranslationContext } from '@ieo/Components/Form/ieo/translation';

const queryClient = new QueryClient();

/**
 * Renders the RegisterForm component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @returns {ReactElement} RegisterForm component
 */

const RegisterForm = props => {
  const {
    sectionContent,
    pageData,
    EnrollSectionSt,
    prgData,
    couponData,
    region,
    lang,
    continueBtn,
    pageName,
    context,
    setContext
  } = props;
  const transProviderObj =
    (pageData._allSharedJsonLocales?.length &&
      pageData._allSharedJsonLocales[0]?.value.json) ||
    pageData.sharedJson?.json;

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageContext.Provider value={{ lang }}>
        <TranslationContext.Provider
          value={transProviderObj}
        // value={content}
        >
          <RegionContext.Provider value={region}>
            <SectionContentContext.Provider value={sectionContent}>
              <IeoRegistrationForm
                programData={prgData}
                EnrollSectionSt={EnrollSectionSt}
                sectionContent={sectionContent}
                pageData={pageData}
                couponData={couponData}
                continueBtn={continueBtn}
                pageName={pageName}
                context={context}
                setContext={setContext}
              />
            </SectionContentContext.Provider>
          </RegionContext.Provider>
        </TranslationContext.Provider>

        {/*<Flex*/}
        {/*  gridGap="20px"*/}
        {/*  px={{ base: "0px", md: "40px" }}*/}
        {/*  py="10px"*/}
        {/*>*/}
        {/*  <Input*/}
        {/*    type="text"*/}
        {/*    placeholder='First Name'*/}
        {/*    borderRadius="0px"*/}
        {/*    bg="#F1EBDF"*/}
        {/*    border="none"*/}
        {/*    _focus={{ border: 'none' }}*/}
        {/*    {...EnrollSectionSt[lang].input}*/}
        {/*  />*/}
        {/*  <Input*/}
        {/*    type="text"*/}
        {/*    placeholder='Last Name'*/}
        {/*    borderRadius="0px"*/}
        {/*    bg="#F1EBDF"*/}
        {/*    border="none"*/}
        {/*    _focus={{ border: 'none' }}*/}
        {/*    {...EnrollSectionSt[lang].input}*/}
        {/*  />*/}
        {/*</Flex>*/}
        {/*<Flex*/}
        {/*  gridGap="20px"*/}
        {/*  px={{ base: "0px", md: "40px" }}*/}
        {/*  py="10px"*/}
        {/*>*/}
        {/*  <Input*/}
        {/*    type="text"*/}
        {/*    placeholder='Email'*/}
        {/*    borderRadius="0px"*/}
        {/*    bg="#F1EBDF"*/}
        {/*    border="none"*/}
        {/*    _focus={{ border: 'none' }}*/}
        {/*    {...EnrollSectionSt[lang].input}*/}
        {/*  />*/}
        {/*</Flex>*/}
        {/*<Flex*/}
        {/*  gridGap="20px"*/}
        {/*  px={{ base: "0px", md: "40px" }}*/}
        {/*  py="10px"*/}
        {/*>*/}
        {/*  <Input*/}
        {/*    type="text"*/}
        {/*    placeholder='Mobile Number'*/}
        {/*    borderRadius="0px"*/}
        {/*    bg="#F1EBDF"*/}
        {/*    border="none"*/}
        {/*    _focus={{ border: 'none' }}*/}
        {/*    {...EnrollSectionSt[lang].input}*/}
        {/*  />*/}
        {/*</Flex>*/}
        {/*<Flex*/}
        {/*  gridGap="20px"*/}
        {/*  px={{ base: "0px", md: "40px" }}*/}
        {/*  py="10px"*/}
        {/*>*/}
        {/*  <Select*/}
        {/*    id='country' {...EnrollSectionSt[lang].input}*/}
        {/*    placeholder='India'*/}
        {/*    borderRadius="0px"*/}
        {/*    bg="#F1EBDF"*/}
        {/*    border="none"*/}
        {/*    _focus={{ border: 'none' }}*/}
        {/*  >*/}
        {/*    <option>United Arab Emirates</option>*/}
        {/*    <option>Nigeria</option>*/}
        {/*  </Select>*/}
        {/*</Flex>*/}
        {/*<Flex*/}
        {/*  gridGap="20px"*/}
        {/*  px={{ base: "0px", md: "40px" }}*/}
        {/*  py="10px"*/}
        {/*>*/}
        {/*  <NextLink*/}
        {/*    href="#"*/}
        {/*    passHref*/}
        {/*    legacyBehavior*/}
        {/*  >*/}
        {/*    <Link*/}
        {/*      bg="#3652B8"*/}
        {/*      _hover={{ bg: '#142C80' }}*/}
        {/*      px="40px"*/}
        {/*      py="10px"*/}
        {/*      color="#fff"*/}
        {/*      borderRadius="3xl"*/}
        {/*      {...EnrollSectionSt[lang].button}*/}
        {/*    >*/}
        {/*      Continue*/}
        {/*    </Link>*/}
        {/*  </NextLink>*/}
        {/*</Flex>*/}
      </LanguageContext.Provider>
    </QueryClientProvider>
  );
};

export default RegisterForm;
