import { RegionList } from '@constants/RegionList';
import { programTypes } from '@ieo/Components/Form/ieo/utils';

function getDataLayer() {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
}

export function analyticsEvent(event) {
  dataLayerPush({
    event: event,
  });
}

export function dataLayerPush(data) {
  getDataLayer().push(data);
}

/*
"dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
dataLayer.push({
  event: ""purchase"",
  ecommerce: {
      transaction_id: """", // Taransaction id
      value: <value>,// pay price (The price at which user purchased the program.
             It will be same as actual price in case of zero discount)
      tax: <value>, // Actual Tax Amount
      currency: ""{{Dynamic}}"",// currency code (Ex - INR,USD,AUD etc.)
      coupon: """", // coupon code if any
      items: [
   {
      item_id: ""{{Dynamic}}"",// id of the program
      item_name:  ""{{Dynamic}}"",// Name of the program (Ex - TYLS_Inner_Engineering)
      affiliation: """",
      coupon: """",// coupon code if any
      discount: ,// Actual Discount if any (actual price - paid price)
      index: ,
      item_brand:  ""{{Dynamic}}"", // Country Code (Ex - US,IN etc.)
      item_category:  ""{{Dynamic}}"", // Language (Ex - English,Spanish etc.)
      item_category2: ""{{Dynamic}}"",// Bay Name (not reuired for IE)
      item_category3: """",
      item_category4: """",
      item_category5: "" "",
      item_list_id: """",
      item_list_name: """",
      item_variant: """",
      location_id: """",
      price: <value>,// actual price (Listed price - without discount)
      quantity: <value> // no of participants (not reuired for IE)
    }]
  }
});"
 */

function mainGtm(props) {
  const {
    program,
    pageData,
    finalAmount,
    event1,
    item_name,
    itemCatg3,
    item_category,
    item_category2,
    item_category4,
    item_category5,
  } = props;
  // console.log('props', props, RegionList[pageData?.country]);
  dataLayerPush({
    event: event1,
    ecommerce: {
      currency: program?.curr || '', // currency code (Ex - INR,USD,AUD etc.)
      value: finalAmount || '',
      items: [
        {
          item_id: programTypes.IEO, // id of the program
          item_name: item_name || '', // Name of the program (Ex - TYLS Inner Engineering)
          affiliation: '',
          coupon: '',
          discount: 0, // Actual Discount if any (actual price - pay price)
          index: '',
          item_brand: pageData?.country || '', // Country Code (Ex - US,IN etc.)
          item_category: item_category || program?.lang || '', // Language (Ex - English,Spanish etc.)
          item_category2: item_category2 || '', // Bay Name (not required for IE)
          item_category3: itemCatg3 || '', // New_User or Repeat_User
          item_category4: item_category4 || '',
          item_category5: item_category5 || RegionList[pageData?.country] || '',
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: finalAmount,
          quantity: '',
        },
      ],
    },
  });
}
export const gtmObject = ({
  program,
  IE_LP_Location,
  disableFirstLastEmailFields,
  isReducedFee,
  updatedProgramFee,
  originalProgramFee,
  pageData,
  item_name,
  event1,
  event,
  item_category,
  item_category2,
  item_category4,
  item_category5,
}) => {
  const finalAmount = isReducedFee ? updatedProgramFee : originalProgramFee;
  let itemCatg3 = 'New_User';
  if (!finalAmount) {
    itemCatg3 = 'Free_User';
  } else if (disableFirstLastEmailFields) {
    itemCatg3 = 'Repeat_User';
  }

  dataLayerPush({
    event: event || '',
    IE_Currency: program?.curr || '',
    IE_LP_Location: disableFirstLastEmailFields
      ? 'SSO'
      : 'Inline_form' || IE_LP_Location || '',
    IE_AMOUNT: finalAmount || '',
    IE_Country: pageData?.country || '',
  });
  dataLayerPush({ ecommerce: null });
  mainGtm({
    program,
    pageData,
    finalAmount,
    event1,
    item_name,
    itemCatg3,
    item_category,
    item_category2,
    item_category4,
    item_category5,
  });
};

export function reportProgramConversion(
  data,
  { fullPrice: fullPriceProp, language, flow },
  isGiftPage
) {
  try {
    const fullPrice = parseInt(fullPriceProp) || 0;
    dataLayerPush({ ecommerce: null });
    dataLayerPush({
      event: 'purchase',
      ecommerce: {
        transaction_id: data.txnId,
        value: data.tCost || '',
        tax: 0,
        currency: data.pmtCurr || '',
        coupon: data.code || '',
        items: [
          {
            item_id: programTypes.IEO,
            item_name: isGiftPage
              ? 'Gift Inner Engineering'
              : 'TYLS Inner Engineering',
            affiliation: '',
            coupon: data.code || '',
            discount: fullPrice ? fullPrice - (data.tCost || data.price) : 0,
            index: 1,
            item_brand: data.country || data.pgmCtry,
            item_category: language,
            item_category2: '',
            item_category3: data.item_category3,
            item_category4: '',
            item_category5:
              RegionList[data.country || data.pgmCtry] || 'Americas',
            item_list_id: '',
            item_list_name: '',
            item_variant: isGiftPage
              ? 'Gift'
              : flow === 'yo'
              ? 'youth offer'
              : '',
            location_id: '',
            price: fullPrice,
          },
        ],
      },
    });
  } catch (error) {
    console.error('Error reporting analytics conversion', error);
  }
}
