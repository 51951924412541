import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    textHeading: {
      fontSize: {
        base: '22px',
        md: '36px',
      },
      fontFamily: {
        base: lang ? config.langFont[lang] : 'FedraSerifAStdBook',
        md: lang ? config.langFont[lang] : 'FedraSerifAStdBook',
      },
      lineHeight: { base: '26px', md: '43px' },
      fontWeight: {
        base: lang ? '500' : 'normal',
        md: '500',
      },
    },
    joinLive: {
      fontFamily: lang ? config.langFont[lang] : 'FedraSansStd-book',
      fontSize: {
        base: '16px',
        md: '20px',
      },
      fontWeight: '400',
      lineHeight: { base: '19px', md: '19px', lg: '24px' },
    },
    date: {
      fontFamily: fontFamily,
      fontSize: {
        base: '20px',
        md: '23px',
      },
      fontWeight: '500',
      lineHeight: { base: '24px', md: '24px', lg: '27px' },
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const AdBanner2St = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({ lang: 'ar' }),
  id: commonLangStyle({ lang: 'id' }),
};
export default AdBanner2St;
