/* Built In Imports */
import { useRouter } from 'next/router';
import { useState } from 'react';

/* External Imports */
import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { RegionList } from '@constants/RegionList';
import { gtmObject } from '@ieo/Components/Form/ieo/api/analyticsAPi';
import { useLanguage } from '@ieo/Components/Form/ieo/context/languageContext';
import {
  useIsIndia,
  useRegion,
} from '@ieo/Components/Form/ieo/context/regionContext';
import { useTranslation } from '@ieo/Components/Form/ieo/translation';
import { programTypes } from '@ieo/Components/Form/ieo/utils';
import { mixPanel } from '@utils/Mixpanel';

/* Hooks */
import { usePageType } from '@hooks/usePageType';

export const IeoFormFieldGrid = props => (
  <Grid
    gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
    gridColumnGap={4}
    gridRowGap={4}
    css={{ '-webkit-grid-gap': 4 }}
    {...props}
  />
);

const ArrowDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M4 9L12 17L20 9" stroke="#F8E1C7" stroke-width="2.66667" />
    </svg>
  );
};

const IeoFormLanguageDropdown = ({
  selectedProgram,
  setSelectedProgram,
  programData,
  EnrollSectionSt,
  canChangeLanguage,
  pageName,
}) => {
  const lang = useLanguage();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const getLanguageName = code => t(`languages.${code?.toLowerCase() || 'en'}`);
  let programArr = [];
  const isIndia = useIsIndia();
  const router = useRouter();

  if (isIndia) {
    if (pageName === 'yo') {
      programArr = [...programData];
    } else {
      config.orderedLangList[lang || 'en']?.forEach(language => {
        const program = programData.filter(item => {
          return item.lang === language.toUpperCase();
        });

        if (program.length > 0) {
          programArr.push(program[0]);
        }
      });
    }
  } else {
    programArr = [...programData];
  }

  if (
    router.asPath.includes('/inner-engineering-pc-2') ||
    router.asPath.includes('/inner-engineering/offer/corporate-register')
  ) {
    canChangeLanguage = false;
    programArr = programArr.filter(item => {
      return 'EN' !== selectedProgram?.lang?.toUpperCase();
    });
  } else {
    programArr = programArr.filter(item => {
      // console.log('electedProgram?.lang--', item.lang.toUpperCase(), selectedProgram?.lang?.toUpperCase())
      return item.lang.toUpperCase() !== selectedProgram?.lang?.toUpperCase();
    });
  }

  // consoleLog(programArr, "programData", programData, "programArr")
  return (
    <Button
      id="enroll-language-switcher-btn"
      bg="transparent"
      color="inherit"
      fontSize={{ base: '16px', md: '18px' }}
      variant="link"
      // h={{ base: '20px', md: '21px' }}
      rounded="full"
      fontWeight={350}
      display="inline-flex"
      _hover={{ border: '0px solid #B7987E' }}
      _focus={{ border: '0px solid #B7987E' }}
      _active={{ border: '0px solid #B7987E' }}
      rightIcon={canChangeLanguage ? <ArrowDownIcon /> : null}
      onClick={
        canChangeLanguage ? () => setIsDropdownOpen(!isDropdownOpen) : undefined
      }
      // onMouseLeave={
      //   canChangeLanguage ? () => setIsDropdownOpen(!isDropdownOpen) : undefined
      // }
      //onBlur={() => setCatIsOpen(false)}
      className="selectButton"
      textTransform="capitalize"
      fontFamily="FedraSansStd-medium"
      mr={{ base: lang === 'id' ? '8px' : '51px', md: '30px' }}
      justifyContent="center"
      cursor={canChangeLanguage ? 'pointer' : 'default'}
      {...EnrollSectionSt[lang]?.price}
    >
      <Text
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        fontFamily="FedraSansStd-medium"
        w={{ base: lang === 'id' ? '115px' : 'auto', sm: 'auto' }}
        textAlign="left"
      >
        {getLanguageName(selectedProgram?.lang)}
      </Text>
      {canChangeLanguage && isDropdownOpen && (
        <Flex
          className="options"
          alignItems="flex-start"
          alignContent="flex-start"
          onMouseLeave={() => {
            setIsDropdownOpen(!isDropdownOpen);
          }}
          // onClick={() => setCatIsOpen(false)}
          position="absolute"
          top={{ base: '48px' }}
          zIndex="100"
          left="0"
          boxShadow="0px 0.75926px 2.36111px 0px rgba(0, 0, 0, 0.03), 0px 3.34074px 4.88889px 0px rgba(0, 0, 0, 0.05), 0px 8.2px 9.75px 0px rgba(0, 0, 0, 0.06), 0px 15.79259px 19.11111px 0px rgba(0, 0, 0, 0.07), 0px 26.57407px 35.13889px 0px rgba(0, 0, 0, 0.09), 0px 41px 60px 0px rgba(0, 0, 0, 0.12)"
          flexDir="column"
          bg="#ffffff"
          mt={{ base: '0', md: '0' }}
          p={{ base: '10px 15px', md: '10px 15px' }}
        >
          {programArr.map((program, index) => {
            return (
              <Link
                key={index}
                color="#6D6358"
                fontFamily="FedraSansStd-medium"
                textAlign="left"
                fontSize={'16px'}
                _hover={{
                  color: '#F37021',
                  fontWeight: '400',
                }}
                onClick={() => setSelectedProgram(program)}
                mb={3}
              >
                {getLanguageName(program?.lang)}
              </Link>
            );
          })}
        </Flex>
      )}
    </Button>
  );
};

export const IeoFormHeader = ({
  EnrollSectionSt,
  program,
  originalProgramFee,
  updatedProgramFee,
  isReducedFee,
  selectedProgram,
  setSelectedProgram,
  programData,
  canChangeLanguage,
  showPrice,
  isTransfer,
  isGiftingFlow,
  pageName,
  region,
}) => {
  const lang = useLanguage();
  const { t } = useTranslation();
  const isIndia = useIsIndia();
  const vernacularLang = selectedProgram?.lang?.toLowerCase();
  return (
    <Flex
      bg="#B49168"
      color="#fff"
      w="full"
      px={{ base: '16px', md: '40px' }}
      flexDir="column"
      py="16px"
    >
      <Flex
        flexDir="row"
        w="full"
        justifyContent="space-between"
        alignItems={'flex-end'}
        color={'#F8E1C7'}
      >
        <div>
          {isGiftingFlow && (
            <>
              {isIndia && (
                <>
                  <Box {...EnrollSectionSt[lang]?.enrollIn}>
                    {t('formFields.giftInLanguage')}
                  </Box>
                  <IeoFormLanguageDropdown
                    EnrollSectionSt={EnrollSectionSt}
                    selectedProgram={selectedProgram}
                    setSelectedProgram={setSelectedProgram}
                    programData={programData}
                    canChangeLanguage={canChangeLanguage}
                    pageName={pageName}
                    region={region}
                    lang={lang}
                  />
                </>
              )}
              {!isIndia && (
                <>
                  <Box {...EnrollSectionSt[lang]?.enrollIn}>
                    {t('formFields.program')}
                  </Box>
                  <Text
                    whiteSpace="nowrap"
                    fontSize="32px"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontFamily="FedraSansStd-medium"
                  >
                    {t('formFields.fee')}
                  </Text>
                </>
              )}
            </>
          )}

          {!isGiftingFlow && (
            <>
              <Box {...EnrollSectionSt[lang]?.enrollIn}>
                {t(
                  isTransfer ? 'formFields.continueWith' : 'formFields.enrollIn'
                )}
              </Box>
              <IeoFormLanguageDropdown
                EnrollSectionSt={EnrollSectionSt}
                selectedProgram={selectedProgram}
                setSelectedProgram={setSelectedProgram}
                programData={programData}
                canChangeLanguage={canChangeLanguage}
                pageName={pageName}
                region={region}
                lang={lang}
              />
            </>
          )}
        </div>
        {/*{program && <Select value={program.lang}>*/}
        {/*</Select>}*/}
        {showPrice && lang === 'id' ? (
          <div>
            {isReducedFee && (
              <Box
                {...EnrollSectionSt[lang]?.price}
                fontSize={'22px'}
                textDecoration={'line-through'}
              >
                {program?.curr}{' '}
                {new Intl.NumberFormat('id').format(originalProgramFee)}
              </Box>
            )}
            <Box {...EnrollSectionSt[lang]?.price}>
              {program?.curr}{' '}
              {new Intl.NumberFormat('id').format(
                isReducedFee ? updatedProgramFee : originalProgramFee
              )}
            </Box>
          </div>
        ) : (
          <div />
        )}

        {showPrice && lang !== 'id' ? (
          <div>
            {isReducedFee && (
              <Box
                {...EnrollSectionSt[lang]?.price}
                fontSize={'22px'}
                textDecoration={'line-through'}
              >
                {program?.curr} {originalProgramFee}
              </Box>
            )}
            <Box {...EnrollSectionSt[lang]?.price}>
              {program?.curr}{' '}
              {isReducedFee ? updatedProgramFee : originalProgramFee}
            </Box>
          </div>
        ) : (
          <div />
        )}
      </Flex>
      {pageName !== 'corporate-register' &&
        region === 'in' &&
        vernacularLang !== 'en' &&
        vernacularLang !== 'ta' && (
          <Flex
            flexDir="row"
            w="full"
            justifyContent="center"
            color={'#F8E1C7'}
          >
            <Text
              id="dubbed-voice-text"
              py={5}
              fontSize={{ base: '14px', md: '20px' }}
              lineHeight={{ base: '19.04px', md: '29px' }}
              fontFamily="FedraSansStd-medium"
              fontWeight={vernacularLang === 'en' ? '400' : '500'}
              color="rgb(18,22,111)"
            >
              {t('formContent.dubbedMessage')}
            </Text>
          </Flex>
        )}
    </Flex>
  );
};

export const IeoFormSso = ({
  EnrollSectionSt,
  pageData,
  isGiftingFlow,
  continueBtn,
  program,
}) => {
  const lang = useLanguage();
  const region = useRegion();
  const router = useRouter();
  const { isGiftPage } = usePageType();
  let requestUrl = `${
    config.BASE_PATH
  }/${region}/${lang}/inner-engineering/register?lang=${program?.lang?.toLowerCase()}`;
  if (isGiftingFlow) {
    requestUrl = `${
      config.BASE_PATH
    }/${region}/${lang}/inner-engineering/gift?lang=${program?.lang.toLowerCase()}`;
  }
  if (router.query.vouchercode) {
    requestUrl = `${requestUrl}&vouchercode=${router.query.vouchercode}`;
  }

  if (router.query?.type === 'type=transfer') {
    const params = new URLSearchParams({
      type: router.query.type,
      lang: router.query.lang,
    });
    requestUrl = `${requestUrl}&${params.toString()} `;
  } else if (isGiftingFlow) {
    const params = new URLSearchParams({
      register: true,
    });
    requestUrl = `${requestUrl}&${params.toString()} `;
  }

  const isArmyOfferURL = router.asPath.includes('armed-forces');

  const gtmClick = () => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'IE_continue_click',
      });

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'add_to_wishlist',
        ecommerce: {
          currency: '', // currency code (Ex - INR,USD,AUD etc.)
          value: '',
          items: [
            {
              item_id: programTypes.IEO, // id of the program
              item_name: isGiftPage
                ? 'Gift Inner Engineering'
                : 'TYLS Inner Engineering', // Name of the program (Ex - TYLS_Inner_Engineering)
              affiliation: '',
              coupon: '',
              discount: 0, //Actual Discount if any (actual price - pay price)
              index: '',
              item_brand: '', // Country Code (Ex - US,IN etc.)
              item_category: program?.lang || '', // Language (Ex - English,Spanish etc.)
              item_category2: '', // Bay Name (not reuired for IE)
              item_category3: '', // New_User or Repeat_User
              item_category4: 'IE_continue_click',
              item_category5: RegionList[pageData.country]
                ? RegionList[pageData.country]
                : 'Americas',
              item_list_id: '',
              item_list_name: '',
              item_variant: '',
              location_id: '',
              price: '',
              quantity: '',
            },
          ],
        },
      });
    }

    gtmObject({
      event: isGiftPage ? 'IE_Gift_Inner_Engineering' : 'IE_Register_Now',
      IE_LP_Location: 'IE_continue_click',
      event1: 'view_item',
      item_name: isGiftPage
        ? 'Gift Inner Engineering'
        : 'TYLS Inner Engineering',
      item_category4: 'IE_continue_click',
    });
    const Mixpanel = mixPanel(router.asPath);
    if (Object.keys(Mixpanel)?.length > 0) {
      Mixpanel.track('ie_registration_initiated', {
        enrollment_method: 'Social Media',
      });
    }
  };

  const { t } = useTranslation();
  return (
    <>
      {/* <Text py="20px" {...EnrollSectionSt[lang].p} textAlign="center" color="#433F3C">
        Already Have an Isha Account?
      </Text> */}
      {continueBtn != 'multiple' && (
        <Link
          id="enroll-continue-button"
          href={`${
            config.FACADE_BASE_PATH
          }/login?request_url=${encodeURIComponent(
            requestUrl
          )}&action=0&force_consent=1`}
          bg="#E86F34"
          _hover={{ bg: '#BC570F' }}
          w={{ base: '95%', sm: '90%', md: '50%', lg: '54.8%' }}
          display={isArmyOfferURL ? 'none' : 'flex'}
          justifyContent="center"
          alignItems="center"
          mx="auto"
          px="10px"
          py="10px"
          color="#fff"
          borderRadius="full"
          minH="60px"
          mt={{ base: '30px', md: '50px' }}
          mb={{ md: '10px' }}
          {...EnrollSectionSt[lang]?.button}
          onClick={() => gtmClick()}
          flexDir={lang === 'ar' ? 'row-reverse' : 'row'}
        >
          {t('formFields.continueWith')}
          <Box
            bg="#fff"
            display="flex"
            h="70%"
            w="1px"
            mx={{ base: '8px', md: '14px' }}
          ></Box>{' '}
          <LazyLoadImageComponent
            src="https://static.sadhguru.org/d/46272/1694854071-login-icons.svg"
            alt=""
          />
        </Link>
      )}

      {continueBtn === 'multiple' && (
        <Flex
          w={{ base: '95%', sm: '90%', md: '60%', lg: '60%' }}
          justifyContent="center"
          alignItems="center"
          mx="auto"
          px="10px"
          py="10px"
          color="#5B5237"
          borderRadius="full"
          minH="60px"
          mt={{ base: '30px', md: '50px' }}
          mb={{ md: '10px' }}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Box mr="20px" mb={{ base: '20px', md: '0' }} fontSize="18px">
            {t('formFields.continueWith')}
          </Box>
          <Flex gridGap="6px">
            <Link
              href={`${
                config.FACADE_BASE_PATH
              }/login?request_url=${encodeURIComponent(
                requestUrl
              )}&action=0&force_consent=1`}
              // bg="#E86F34"
              // _hover={{ bg: '#BC570F' }}
              // _hover={{ bg: '#BC570F' }}
              width="50px"
              h="50px"
              {...EnrollSectionSt[lang]?.button}
              onClick={() => gtmClick()}
            >
              <Box
                width="50px"
                h="50px"
                bg="#E86F34"
                _hover={{ bg: '#BC570F' }}
                borderRadius="50%"
                p="10px 15px"
              >
                <LazyLoadImageComponent
                  src="https://static.sadhguru.org/d/46272/1706157900-phone-device-iphone-mobile.png"
                  alt=""
                />
              </Box>
            </Link>
            <Link
              href={`${
                config.FACADE_BASE_PATH
              }/login?request_url=${encodeURIComponent(
                requestUrl
              )}&action=0&force_consent=1`}
              // bg="#E86F34"
              width="50px"
              h="50px"
              {...EnrollSectionSt[lang]?.button}
              onClick={() => gtmClick()}
            >
              <Box
                width="50px"
                h="50px"
                bg="#E86F34"
                _hover={{ bg: '#BC570F' }}
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <LazyLoadImageComponent
                  src="https://static.sadhguru.org/d/46272/1706157718-group-1171276722.png"
                  alt=""
                />
              </Box>
            </Link>

            <Link
              href={`${
                config.FACADE_BASE_PATH
              }/login?request_url=${encodeURIComponent(
                requestUrl
              )}&action=0&force_consent=1`}
              // bg="#E86F34"
              width="50px"
              h="50px"
              // mr="10px"
              {...EnrollSectionSt[lang]?.button}
              onClick={() => gtmClick()}
            >
              <Box
                width="50px"
                h="50px"
                bg="#E86F34"
                _hover={{ bg: '#BC570F' }}
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <LazyLoadImageComponent
                  src="https://static.sadhguru.org/d/46272/1706157885-vector.png"
                  alt=""
                />
              </Box>
            </Link>
            <Link
              href={`${
                config.FACADE_BASE_PATH
              }/login?request_url=${encodeURIComponent(
                requestUrl
              )}&action=0&force_consent=1`}
              // bg="#E86F34"
              // _hover={{ bg: '#BC570F' }}
              // _hover={{ bg: '#BC570F' }}
              width="50px"
              h="50px"
              // mr="10px"
              {...EnrollSectionSt[lang]?.button}
              onClick={() => gtmClick()}
            >
              <Box
                width="50px"
                h="50px"
                bg="#E86F34"
                _hover={{ bg: '#BC570F' }}
                borderRadius="50%"
                mr="10px"
                p="10px 15px"
              >
                <LazyLoadImageComponent
                  src="https://static.sadhguru.org/d/46272/1706157894-vector-1.png"
                  alt=""
                />
              </Box>
            </Link>
          </Flex>
        </Flex>
      )}
      <Box
        position="relative"
        padding={{ base: '30px 15px', md: '20px' }}
        mx="auto"
        w="100%"
        maxW="500px"
      >
        <Box
          borderBottom={{
            base: '1px solid #6D6358',
            md: '2px solid #6D6358',
          }}
          opacity={0.3}
          display={isArmyOfferURL && 'none'}
        />
        <AbsoluteCenter
          bg="white"
          px="4"
          color="#A88954"
          {...EnrollSectionSt[lang]?.or}
          display={isArmyOfferURL && 'none'}
        >
          {t('formFields.or') || 'or'}
        </AbsoluteCenter>
      </Box>
    </>
  );
};
