export const phoneWithCountryCode = [
  {
    name: 'Afghanistan',
    iso2_code: 'AF',
    phone_code: '+93',
  },
  {
    name: 'Albania',
    iso2_code: 'AL',
    phone_code: '+355',
  },
  {
    name: 'Algeria',
    iso2_code: 'DZ',
    phone_code: '+213',
  },
  {
    name: 'American Samoa',
    iso2_code: 'AS',
    phone_code: '+1684',
  },
  {
    name: 'Andorra',
    iso2_code: 'AD',
    phone_code: '+376',
  },
  {
    name: 'Angola',
    iso2_code: 'AO',
    phone_code: '+244',
  },
  {
    name: 'Anguilla',
    iso2_code: 'AI',
    phone_code: '+1264',
  },
  {
    name: 'Antarctica',
    iso2_code: 'AQ',
    phone_code: '+672',
  },
  {
    name: 'Antigua and Barbuda',
    iso2_code: 'AG',
    phone_code: '+1268',
  },
  {
    name: 'Argentina',
    iso2_code: 'AR',
    phone_code: '+54',
  },
  {
    name: 'Armenia',
    iso2_code: 'AM',
    phone_code: '+374',
  },
  {
    name: 'Aruba',
    iso2_code: 'AW',
    phone_code: '+297',
  },
  {
    name: 'Australia',
    iso2_code: 'AU',
    phone_code: '+61',
  },
  {
    name: 'Austria',
    iso2_code: 'AT',
    phone_code: '+43',
  },
  {
    name: 'Azerbaijan',
    iso2_code: 'AZ',
    phone_code: '+994',
  },
  {
    name: 'Bahamas',
    iso2_code: 'BS',
    phone_code: '+1242',
  },
  {
    name: 'Bahrain',
    iso2_code: 'BH',
    phone_code: '+973',
  },
  {
    name: 'Bangladesh',
    iso2_code: 'BD',
    phone_code: '+880',
  },
  {
    name: 'Barbados',
    iso2_code: 'BB',
    phone_code: '+1246',
  },
  {
    name: 'Belarus',
    iso2_code: 'BY',
    phone_code: '+375',
  },
  {
    name: 'Belgium',
    iso2_code: 'BE',
    phone_code: '+32',
  },
  {
    name: 'Belize',
    iso2_code: 'BZ',
    phone_code: '+501',
  },
  {
    name: 'Benin',
    iso2_code: 'BJ',
    phone_code: '+229',
  },
  {
    name: 'Bermuda',
    iso2_code: 'BM',
    phone_code: '+1441',
  },
  {
    name: 'Bhutan',
    iso2_code: 'BT',
    phone_code: '+975',
  },
  {
    name: 'Bolivia',
    iso2_code: 'BO',
    phone_code: '+591',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    iso2_code: 'BQ',
    phone_code: '+599',
  },
  {
    name: 'Bosnia and Herzegovina',
    iso2_code: 'BA',
    phone_code: '+387',
  },
  {
    name: 'Botswana',
    iso2_code: 'BW',
    phone_code: '+267',
  },
  {
    name: 'Bouvet Island',
    iso2_code: 'BV',
    phone_code: '+55',
  },
  {
    name: 'Brazil',
    iso2_code: 'BR',
    phone_code: '+55',
  },
  {
    name: 'British Indian Ocean Territory',
    iso2_code: 'IO',
    phone_code: '+246',
  },
  {
    name: 'Brunei Darussalam',
    iso2_code: 'BN',
    phone_code: '+673',
  },
  {
    name: 'Bulgaria',
    iso2_code: 'BG',
    phone_code: '+359',
  },
  {
    name: 'Burkina Faso',
    iso2_code: 'BF',
    phone_code: '+226',
  },
  {
    name: 'Burundi',
    iso2_code: 'BI',
    phone_code: '+257',
  },
  {
    name: 'Cambodia',
    iso2_code: 'KH',
    phone_code: '+855',
  },
  {
    name: 'Cameroon',
    iso2_code: 'CM',
    phone_code: '+237',
  },
  {
    name: 'Canada',
    iso2_code: 'CA',
    phone_code: '+1',
  },
  {
    name: 'Cape Verde',
    iso2_code: 'CV',
    phone_code: '+238',
  },
  {
    name: 'Cayman Islands',
    iso2_code: 'KY',
    phone_code: '+1345',
  },
  {
    name: 'Central African Republic',
    iso2_code: 'CF',
    phone_code: '+236',
  },
  {
    name: 'Chad',
    iso2_code: 'TD',
    phone_code: '+235',
  },
  {
    name: 'Chile',
    iso2_code: 'CL',
    phone_code: '+56',
  },
  {
    name: 'China',
    iso2_code: 'CN',
    phone_code: '+86',
  },
  {
    name: 'Christmas Island',
    iso2_code: 'CX',
    phone_code: '+61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    iso2_code: 'CC',
    phone_code: '+61',
  },
  {
    name: 'Colombia',
    iso2_code: 'CO',
    phone_code: '+57',
  },
  {
    name: 'Comoros',
    iso2_code: 'KM',
    phone_code: '+269',
  },
  {
    name: 'Congo',
    iso2_code: 'CG',
    phone_code: '+243',
  },
  {
    name: 'Cook Islands',
    iso2_code: 'CK',
    phone_code: '+682',
  },
  {
    name: 'Costa Rica',
    iso2_code: 'CR',
    phone_code: '+506',
  },
  {
    name: 'Croatia',
    iso2_code: 'HR',
    phone_code: '+385',
  },
  {
    name: 'Cuba',
    iso2_code: 'CU',
    phone_code: '+53',
  },
  {
    name: 'Curaçao',
    iso2_code: 'CW',
    phone_code: '+599',
  },
  {
    name: 'Cyprus',
    iso2_code: 'CY',
    phone_code: '+357',
  },
  {
    name: 'Czech Republic',
    iso2_code: 'CZ',
    phone_code: '+420',
  },
  {
    name: "Côte d'Ivoire",
    iso2_code: 'CI',
    phone_code: '+225',
  },
  {
    name: 'Democratic Republic of the Congo',
    iso2_code: 'CD',
    phone_code: '+242',
  },
  {
    name: 'Denmark',
    iso2_code: 'DK',
    phone_code: '+45',
  },
  {
    name: 'Djibouti',
    iso2_code: 'DJ',
    phone_code: '+253',
  },
  {
    name: 'Dominica',
    iso2_code: 'DM',
    phone_code: '+1767',
  },
  {
    name: 'Dominican Republic',
    iso2_code: 'DO',
    phone_code: '+1849',
  },
  {
    name: 'Ecuador',
    iso2_code: 'EC',
    phone_code: '+593',
  },
  {
    name: 'Egypt',
    iso2_code: 'EG',
    phone_code: '+20',
  },
  {
    name: 'El Salvador',
    iso2_code: 'SV',
    phone_code: '+503',
  },
  {
    name: 'Equatorial Guinea',
    iso2_code: 'GQ',
    phone_code: '+240',
  },
  {
    name: 'Eritrea',
    iso2_code: 'ER',
    phone_code: '+291',
  },
  {
    name: 'Estonia',
    iso2_code: 'EE',
    phone_code: '+372',
  },
  {
    name: 'Eswatini',
    iso2_code: 'SZ',
    phone_code: '+268',
  },
  {
    name: 'Ethiopia',
    iso2_code: 'ET',
    phone_code: '+251',
  },
  {
    name: 'Falkland Islands',
    iso2_code: 'FK',
    phone_code: '+500',
  },
  {
    name: 'Faroe Islands',
    iso2_code: 'FO',
    phone_code: '+298',
  },
  {
    name: 'Fiji',
    iso2_code: 'FJ',
    phone_code: '+679',
  },
  {
    name: 'Finland',
    iso2_code: 'FI',
    phone_code: '+358',
  },
  {
    name: 'France',
    iso2_code: 'FR',
    phone_code: '+33',
  },
  {
    name: 'French Guiana',
    iso2_code: 'GF',
    phone_code: '+594',
  },
  {
    name: 'French Polynesia',
    iso2_code: 'PF',
    phone_code: '+689',
  },
  {
    name: 'French Southern Territories',
    iso2_code: 'TF',
    phone_code: '+262',
  },
  {
    name: 'Gabon',
    iso2_code: 'GA',
    phone_code: '+241',
  },
  {
    name: 'Gambia',
    iso2_code: 'GM',
    phone_code: '+220',
  },
  {
    name: 'Georgia',
    iso2_code: 'GE',
    phone_code: '+995',
  },
  {
    name: 'Germany',
    iso2_code: 'DE',
    phone_code: '+49',
  },
  {
    name: 'Ghana',
    iso2_code: 'GH',
    phone_code: '+233',
  },
  {
    name: 'Gibraltar',
    iso2_code: 'GI',
    phone_code: '+350',
  },
  {
    name: 'Greece',
    iso2_code: 'GR',
    phone_code: '+30',
  },
  {
    name: 'Greenland',
    iso2_code: 'GL',
    phone_code: '+299',
  },
  {
    name: 'Grenada',
    iso2_code: 'GD',
    phone_code: '+1473',
  },
  {
    name: 'Guadeloupe',
    iso2_code: 'GP',
    phone_code: '+590',
  },
  {
    name: 'Guam',
    iso2_code: 'GU',
    phone_code: '+1671',
  },
  {
    name: 'Guatemala',
    iso2_code: 'GT',
    phone_code: '+502',
  },
  {
    name: 'Guernsey',
    iso2_code: 'GG',
    phone_code: '+44',
  },
  {
    name: 'Guinea',
    iso2_code: 'GN',
    phone_code: '+224',
  },
  {
    name: 'Guinea-Bissau',
    iso2_code: 'GW',
    phone_code: '+245',
  },
  {
    name: 'Guyana',
    iso2_code: 'GY',
    phone_code: '+592',
  },
  {
    name: 'Haiti',
    iso2_code: 'HT',
    phone_code: '+509',
  },
  {
    name: 'Heard Island and McDonald Islands',
    iso2_code: 'HM',
    phone_code: '+672',
  },
  {
    name: 'Holy See',
    iso2_code: 'VA',
    phone_code: '+379',
  },
  {
    name: 'Honduras',
    iso2_code: 'HN',
    phone_code: '+504',
  },
  {
    name: 'Hong Kong',
    iso2_code: 'HK',
    phone_code: '+852',
  },
  {
    name: 'Hungary',
    iso2_code: 'HU',
    phone_code: '+36',
  },
  {
    name: 'Iceland',
    iso2_code: 'IS',
    phone_code: '+354',
  },
  {
    name: 'India',
    iso2_code: 'IN',
    phone_code: '+91',
  },
  {
    name: 'Indonesia',
    iso2_code: 'ID',
    phone_code: '+62',
  },
  {
    name: 'Iran',
    iso2_code: 'IR',
    phone_code: '+98',
  },
  {
    name: 'Iraq',
    iso2_code: 'IQ',
    phone_code: '+964',
  },
  {
    name: 'Ireland',
    iso2_code: 'IE',
    phone_code: '+353',
  },
  {
    name: 'Isle of Man',
    iso2_code: 'IM',
    phone_code: '+44',
  },
  {
    name: 'Israel',
    iso2_code: 'IL',
    phone_code: '+972',
  },
  {
    name: 'Italy',
    iso2_code: 'IT',
    phone_code: '+39',
  },
  {
    name: 'Jamaica',
    iso2_code: 'JM',
    phone_code: '+1876',
  },
  {
    name: 'Japan',
    iso2_code: 'JP',
    phone_code: '+81',
  },
  {
    name: 'Jersey',
    iso2_code: 'JE',
    phone_code: '+44',
  },
  {
    name: 'Jordan',
    iso2_code: 'JO',
    phone_code: '+962',
  },
  {
    name: 'Kazakhstan',
    iso2_code: 'KZ',
    phone_code: '+7',
  },
  {
    name: 'Kenya',
    iso2_code: 'KE',
    phone_code: '+254',
  },
  {
    name: 'Kiribati',
    iso2_code: 'KI',
    phone_code: '+686',
  },
  {
    name: 'Kosovo',
    iso2_code: 'XK',
    phone_code: '+383',
  },
  {
    name: 'Kuwait',
    iso2_code: 'KW',
    phone_code: '+965',
  },
  {
    name: 'Kyrgyzstan',
    iso2_code: 'KG',
    phone_code: '+996',
  },
  {
    name: "Lao People's Democratic Republic",
    iso2_code: 'LA',
    phone_code: '+856',
  },
  {
    name: 'Latvia',
    iso2_code: 'LV',
    phone_code: '+371',
  },
  {
    name: 'Lebanon',
    iso2_code: 'LB',
    phone_code: '+961',
  },
  {
    name: 'Lesotho',
    iso2_code: 'LS',
    phone_code: '+266',
  },
  {
    name: 'Liberia',
    iso2_code: 'LR',
    phone_code: '+231',
  },
  {
    name: 'Libya',
    iso2_code: 'LY',
    phone_code: '+218',
  },
  {
    name: 'Liechtenstein',
    iso2_code: 'LI',
    phone_code: '+423',
  },
  {
    name: 'Lithuania',
    iso2_code: 'LT',
    phone_code: '+370',
  },
  {
    name: 'Luxembourg',
    iso2_code: 'LU',
    phone_code: '+352',
  },
  {
    name: 'Macao',
    iso2_code: 'MO',
    phone_code: '+853',
  },
  {
    name: 'Madagascar',
    iso2_code: 'MG',
    phone_code: '+261',
  },
  {
    name: 'Malawi',
    iso2_code: 'MW',
    phone_code: '+265',
  },
  {
    name: 'Malaysia',
    iso2_code: 'MY',
    phone_code: '+60',
  },
  {
    name: 'Maldives',
    iso2_code: 'MV',
    phone_code: '+960',
  },
  {
    name: 'Mali',
    iso2_code: 'ML',
    phone_code: '+223',
  },
  {
    name: 'Malta',
    iso2_code: 'MT',
    phone_code: '+356',
  },
  {
    name: 'Marshall Islands',
    iso2_code: 'MH',
    phone_code: '+692',
  },
  {
    name: 'Martinique',
    iso2_code: 'MQ',
    phone_code: '+596',
  },
  {
    name: 'Mauritania',
    iso2_code: 'MR',
    phone_code: '+222',
  },
  {
    name: 'Mauritius',
    iso2_code: 'MU',
    phone_code: '+230',
  },
  {
    name: 'Mayotte',
    iso2_code: 'YT',
    phone_code: '+262',
  },
  {
    name: 'Mexico',
    iso2_code: 'MX',
    phone_code: '+52',
  },
  {
    name: 'Micronesia',
    iso2_code: 'FM',
    phone_code: '+691',
  },
  {
    name: 'Moldova',
    iso2_code: 'MD',
    phone_code: '+373',
  },
  {
    name: 'Monaco',
    iso2_code: 'MC',
    phone_code: '+377',
  },
  {
    name: 'Mongolia',
    iso2_code: 'MN',
    phone_code: '+976',
  },
  {
    name: 'Montenegro',
    iso2_code: 'ME',
    phone_code: '+382',
  },
  {
    name: 'Montserrat',
    iso2_code: 'MS',
    phone_code: '+1664',
  },
  {
    name: 'Morocco',
    iso2_code: 'MA',
    phone_code: '+212',
  },
  {
    name: 'Mozambique',
    iso2_code: 'MZ',
    phone_code: '+258',
  },
  {
    name: 'Myanmar',
    iso2_code: 'MM',
    phone_code: '+95',
  },
  {
    name: 'Namibia',
    iso2_code: 'NA',
    phone_code: '+264',
  },
  {
    name: 'Nauru',
    iso2_code: 'NR',
    phone_code: '+674',
  },
  {
    name: 'Nepal',
    iso2_code: 'NP',
    phone_code: '+977',
  },
  {
    name: 'Netherlands',
    iso2_code: 'NL',
    phone_code: '+31',
  },
  {
    name: 'New Caledonia',
    iso2_code: 'NC',
    phone_code: '+687',
  },
  {
    name: 'New Zealand',
    iso2_code: 'NZ',
    phone_code: '+64',
  },
  {
    name: 'Nicaragua',
    iso2_code: 'NI',
    phone_code: '+505',
  },
  {
    name: 'Niger',
    iso2_code: 'NE',
    phone_code: '+227',
  },
  {
    name: 'Nigeria',
    iso2_code: 'NG',
    phone_code: '+234',
  },
  {
    name: 'Niue',
    iso2_code: 'NU',
    phone_code: '+683',
  },
  {
    name: 'Norfolk Island',
    iso2_code: 'NF',
    phone_code: '+672',
  },
  {
    name: 'North Korea',
    iso2_code: 'KP',
    phone_code: '+850',
  },
  {
    name: 'North Macedonia',
    iso2_code: 'MK',
    phone_code: '+389',
  },
  {
    name: 'Northern Mariana Islands',
    iso2_code: 'MP',
    phone_code: '+1670',
  },
  {
    name: 'Norway',
    iso2_code: 'NO',
    phone_code: '+47',
  },
  {
    name: 'Oman',
    iso2_code: 'OM',
    phone_code: '+968',
  },
  {
    name: 'Pakistan',
    iso2_code: 'PK',
    phone_code: '+92',
  },
  {
    name: 'Palau',
    iso2_code: 'PW',
    phone_code: '+680',
  },
  {
    name: 'Palestine',
    iso2_code: 'PS',
    phone_code: '+970',
  },
  {
    name: 'Panama',
    iso2_code: 'PA',
    phone_code: '+507',
  },
  {
    name: 'Papua New Guinea',
    iso2_code: 'PG',
    phone_code: '+675',
  },
  {
    name: 'Paraguay',
    iso2_code: 'PY',
    phone_code: '+595',
  },
  {
    name: 'Peru',
    iso2_code: 'PE',
    phone_code: '+51',
  },
  {
    name: 'Philippines',
    iso2_code: 'PH',
    phone_code: '+63',
  },
  {
    name: 'Pitcairn',
    iso2_code: 'PN',
    phone_code: '+64',
  },
  {
    name: 'Poland',
    iso2_code: 'PL',
    phone_code: '+48',
  },
  {
    name: 'Portugal',
    iso2_code: 'PT',
    phone_code: '+351',
  },
  {
    name: 'Puerto Rico',
    iso2_code: 'PR',
    phone_code: '+1939',
  },
  {
    name: 'Qatar',
    iso2_code: 'QA',
    phone_code: '+974',
  },
  {
    name: 'Romania',
    iso2_code: 'RO',
    phone_code: '+40',
  },
  {
    name: 'Russian Federation',
    iso2_code: 'RU',
    phone_code: '+7',
  },
  {
    name: 'Rwanda',
    iso2_code: 'RW',
    phone_code: '+250',
  },
  {
    name: 'Réunion',
    iso2_code: 'RE',
    phone_code: '+262',
  },
  {
    name: 'Saint Barthélemy',
    iso2_code: 'BL',
    phone_code: '+590',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    iso2_code: 'SH',
    phone_code: '+290',
  },
  {
    name: 'Saint Kitts and Nevis',
    iso2_code: 'KN',
    phone_code: '+1869',
  },
  {
    name: 'Saint Lucia',
    iso2_code: 'LC',
    phone_code: '+1758',
  },
  {
    name: 'Saint Martin',
    iso2_code: 'MF',
    phone_code: '+590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    iso2_code: 'PM',
    phone_code: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso2_code: 'VC',
    phone_code: '+1784',
  },
  {
    name: 'Samoa',
    iso2_code: 'WS',
    phone_code: '+685',
  },
  {
    name: 'San Marino',
    iso2_code: 'SM',
    phone_code: '+378',
  },
  {
    name: 'Sao Tome and Principe',
    iso2_code: 'ST',
    phone_code: '+239',
  },
  {
    name: 'Saudi Arabia',
    iso2_code: 'SA',
    phone_code: '+966',
  },
  {
    name: 'Senegal',
    iso2_code: 'SN',
    phone_code: '+221',
  },
  {
    name: 'Serbia',
    iso2_code: 'RS',
    phone_code: '+381',
  },
  {
    name: 'Seychelles',
    iso2_code: 'SC',
    phone_code: '+248',
  },
  {
    name: 'Sierra Leone',
    iso2_code: 'SL',
    phone_code: '+232',
  },
  {
    name: 'Singapore',
    iso2_code: 'SG',
    phone_code: '+65',
  },
  {
    name: 'Sint Maarten',
    iso2_code: 'SX',
    phone_code: '+1721',
  },
  {
    name: 'Slovakia',
    iso2_code: 'SK',
    phone_code: '+421',
  },
  {
    name: 'Slovenia',
    iso2_code: 'SI',
    phone_code: '+386',
  },
  {
    name: 'Solomon Islands',
    iso2_code: 'SB',
    phone_code: '+677',
  },
  {
    name: 'Somalia',
    iso2_code: 'SO',
    phone_code: '+252',
  },
  {
    name: 'South Africa',
    iso2_code: 'ZA',
    phone_code: '+27',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    iso2_code: 'GS',
    phone_code: '+500',
  },
  {
    name: 'South Korea',
    iso2_code: 'KR',
    phone_code: '+82',
  },
  {
    name: 'South Sudan',
    iso2_code: 'SS',
    phone_code: '+211',
  },
  {
    name: 'Spain',
    iso2_code: 'ES',
    phone_code: '+34',
  },
  {
    name: 'Sri Lanka',
    iso2_code: 'LK',
    phone_code: '+94',
  },
  {
    name: 'Sudan',
    iso2_code: 'SD',
    phone_code: '+249',
  },
  {
    name: 'Suriname',
    iso2_code: 'SR',
    phone_code: '+597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    iso2_code: 'SJ',
    phone_code: '+47',
  },
  {
    name: 'Sweden',
    iso2_code: 'SE',
    phone_code: '+46',
  },
  {
    name: 'Switzerland',
    iso2_code: 'CH',
    phone_code: '+41',
  },
  {
    name: 'Syria',
    iso2_code: 'SY',
    phone_code: '+963',
  },
  {
    name: 'Taiwan',
    iso2_code: 'TW',
    phone_code: '+886',
  },
  {
    name: 'Tajikistan',
    iso2_code: 'TJ',
    phone_code: '+992',
  },
  {
    name: 'Tanzania',
    iso2_code: 'TZ',
    phone_code: '+255',
  },
  {
    name: 'Thailand',
    iso2_code: 'TH',
    phone_code: '+66',
  },
  {
    name: 'Timor-Leste',
    iso2_code: 'TL',
    phone_code: '+670',
  },
  {
    name: 'Togo',
    iso2_code: 'TG',
    phone_code: '+228',
  },
  {
    name: 'Tokelau',
    iso2_code: 'TK',
    phone_code: '+690',
  },
  {
    name: 'Tonga',
    iso2_code: 'TO',
    phone_code: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    iso2_code: 'TT',
    phone_code: '+1868',
  },
  {
    name: 'Tunisia',
    iso2_code: 'TN',
    phone_code: '+216',
  },
  {
    name: 'Turkey',
    iso2_code: 'TR',
    phone_code: '+90',
  },
  {
    name: 'Turkmenistan',
    iso2_code: 'TM',
    phone_code: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    iso2_code: 'TC',
    phone_code: '+1649',
  },
  {
    name: 'Tuvalu',
    iso2_code: 'TV',
    phone_code: '+688',
  },
  {
    name: 'USA Minor Outlying Islands',
    iso2_code: 'UM',
    phone_code: '+699',
  },
  {
    name: 'Uganda',
    iso2_code: 'UG',
    phone_code: '+256',
  },
  {
    name: 'Ukraine',
    iso2_code: 'UA',
    phone_code: '+380',
  },
  {
    name: 'United Arab Emirates',
    iso2_code: 'AE',
    phone_code: '+971',
  },
  {
    name: 'United Kingdom',
    iso2_code: 'GB',
    phone_code: '+44',
  },
  {
    name: 'United States',
    iso2_code: 'US',
    phone_code: '+1',
  },
  {
    name: 'United States Virgin Islands',
    iso2_code: 'VI',
    phone_code: '+1340',
  },
  {
    name: 'Uruguay',
    iso2_code: 'UY',
    phone_code: '+598',
  },
  {
    name: 'Uzbekistan',
    iso2_code: 'UZ',
    phone_code: '+998',
  },
  {
    name: 'Vanuatu',
    iso2_code: 'VU',
    phone_code: '+678',
  },
  {
    name: 'Venezuela',
    iso2_code: 'VE',
    phone_code: '+58',
  },
  {
    name: 'Vietnam',
    iso2_code: 'VN',
    phone_code: '+84',
  },
  {
    name: 'Wallis and Futuna',
    iso2_code: 'WF',
    phone_code: '+681',
  },
  {
    name: 'Western Sahara',
    iso2_code: 'EH',
    phone_code: '+212',
  },
  {
    name: 'Yemen',
    iso2_code: 'YE',
    phone_code: '+967',
  },
  {
    name: 'Zambia',
    iso2_code: 'ZM',
    phone_code: '+260',
  },
  {
    name: 'Zimbabwe',
    iso2_code: 'ZW',
    phone_code: '+263',
  },
];
export const phoneWithoutChinaCode = [
  {
    name: 'Afghanistan',
    iso2_code: 'AF',
    phone_code: '+93',
  },
  {
    name: 'Albania',
    iso2_code: 'AL',
    phone_code: '+355',
  },
  {
    name: 'Algeria',
    iso2_code: 'DZ',
    phone_code: '+213',
  },
  {
    name: 'American Samoa',
    iso2_code: 'AS',
    phone_code: '+1684',
  },
  {
    name: 'Andorra',
    iso2_code: 'AD',
    phone_code: '+376',
  },
  {
    name: 'Angola',
    iso2_code: 'AO',
    phone_code: '+244',
  },
  {
    name: 'Anguilla',
    iso2_code: 'AI',
    phone_code: '+1264',
  },
  {
    name: 'Antarctica',
    iso2_code: 'AQ',
    phone_code: '+672',
  },
  {
    name: 'Antigua and Barbuda',
    iso2_code: 'AG',
    phone_code: '+1268',
  },
  {
    name: 'Argentina',
    iso2_code: 'AR',
    phone_code: '+54',
  },
  {
    name: 'Armenia',
    iso2_code: 'AM',
    phone_code: '+374',
  },
  {
    name: 'Aruba',
    iso2_code: 'AW',
    phone_code: '+297',
  },
  {
    name: 'Australia',
    iso2_code: 'AU',
    phone_code: '+61',
  },
  {
    name: 'Austria',
    iso2_code: 'AT',
    phone_code: '+43',
  },
  {
    name: 'Azerbaijan',
    iso2_code: 'AZ',
    phone_code: '+994',
  },
  {
    name: 'Bahamas',
    iso2_code: 'BS',
    phone_code: '+1242',
  },
  {
    name: 'Bahrain',
    iso2_code: 'BH',
    phone_code: '+973',
  },
  {
    name: 'Bangladesh',
    iso2_code: 'BD',
    phone_code: '+880',
  },
  {
    name: 'Barbados',
    iso2_code: 'BB',
    phone_code: '+1246',
  },
  {
    name: 'Belarus',
    iso2_code: 'BY',
    phone_code: '+375',
  },
  {
    name: 'Belgium',
    iso2_code: 'BE',
    phone_code: '+32',
  },
  {
    name: 'Belize',
    iso2_code: 'BZ',
    phone_code: '+501',
  },
  {
    name: 'Benin',
    iso2_code: 'BJ',
    phone_code: '+229',
  },
  {
    name: 'Bermuda',
    iso2_code: 'BM',
    phone_code: '+1441',
  },
  {
    name: 'Bhutan',
    iso2_code: 'BT',
    phone_code: '+975',
  },
  {
    name: 'Bolivia',
    iso2_code: 'BO',
    phone_code: '+591',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    iso2_code: 'BQ',
    phone_code: '+599',
  },
  {
    name: 'Bosnia and Herzegovina',
    iso2_code: 'BA',
    phone_code: '+387',
  },
  {
    name: 'Botswana',
    iso2_code: 'BW',
    phone_code: '+267',
  },
  {
    name: 'Bouvet Island',
    iso2_code: 'BV',
    phone_code: '+55',
  },
  {
    name: 'Brazil',
    iso2_code: 'BR',
    phone_code: '+55',
  },
  {
    name: 'British Indian Ocean Territory',
    iso2_code: 'IO',
    phone_code: '+246',
  },
  {
    name: 'Brunei Darussalam',
    iso2_code: 'BN',
    phone_code: '+673',
  },
  {
    name: 'Bulgaria',
    iso2_code: 'BG',
    phone_code: '+359',
  },
  {
    name: 'Burkina Faso',
    iso2_code: 'BF',
    phone_code: '+226',
  },
  {
    name: 'Burundi',
    iso2_code: 'BI',
    phone_code: '+257',
  },
  {
    name: 'Cambodia',
    iso2_code: 'KH',
    phone_code: '+855',
  },
  {
    name: 'Cameroon',
    iso2_code: 'CM',
    phone_code: '+237',
  },
  {
    name: 'Canada',
    iso2_code: 'CA',
    phone_code: '+1',
  },
  {
    name: 'Cape Verde',
    iso2_code: 'CV',
    phone_code: '+238',
  },
  {
    name: 'Cayman Islands',
    iso2_code: 'KY',
    phone_code: '+1345',
  },
  {
    name: 'Central African Republic',
    iso2_code: 'CF',
    phone_code: '+236',
  },
  {
    name: 'Chad',
    iso2_code: 'TD',
    phone_code: '+235',
  },
  {
    name: 'Chile',
    iso2_code: 'CL',
    phone_code: '+56',
  },
  // {
  //   name: 'China',
  //   iso2_code: 'CN',
  //   phone_code: '+86',
  // },
  {
    name: 'Christmas Island',
    iso2_code: 'CX',
    phone_code: '+61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    iso2_code: 'CC',
    phone_code: '+61',
  },
  {
    name: 'Colombia',
    iso2_code: 'CO',
    phone_code: '+57',
  },
  {
    name: 'Comoros',
    iso2_code: 'KM',
    phone_code: '+269',
  },
  {
    name: 'Congo',
    iso2_code: 'CG',
    phone_code: '+243',
  },
  {
    name: 'Cook Islands',
    iso2_code: 'CK',
    phone_code: '+682',
  },
  {
    name: 'Costa Rica',
    iso2_code: 'CR',
    phone_code: '+506',
  },
  {
    name: 'Croatia',
    iso2_code: 'HR',
    phone_code: '+385',
  },
  {
    name: 'Cuba',
    iso2_code: 'CU',
    phone_code: '+53',
  },
  {
    name: 'Curaçao',
    iso2_code: 'CW',
    phone_code: '+599',
  },
  {
    name: 'Cyprus',
    iso2_code: 'CY',
    phone_code: '+357',
  },
  {
    name: 'Czech Republic',
    iso2_code: 'CZ',
    phone_code: '+420',
  },
  {
    name: "Côte d'Ivoire",
    iso2_code: 'CI',
    phone_code: '+225',
  },
  {
    name: 'Democratic Republic of the Congo',
    iso2_code: 'CD',
    phone_code: '+242',
  },
  {
    name: 'Denmark',
    iso2_code: 'DK',
    phone_code: '+45',
  },
  {
    name: 'Djibouti',
    iso2_code: 'DJ',
    phone_code: '+253',
  },
  {
    name: 'Dominica',
    iso2_code: 'DM',
    phone_code: '+1767',
  },
  {
    name: 'Dominican Republic',
    iso2_code: 'DO',
    phone_code: '+1849',
  },
  {
    name: 'Ecuador',
    iso2_code: 'EC',
    phone_code: '+593',
  },
  {
    name: 'Egypt',
    iso2_code: 'EG',
    phone_code: '+20',
  },
  {
    name: 'El Salvador',
    iso2_code: 'SV',
    phone_code: '+503',
  },
  {
    name: 'Equatorial Guinea',
    iso2_code: 'GQ',
    phone_code: '+240',
  },
  {
    name: 'Eritrea',
    iso2_code: 'ER',
    phone_code: '+291',
  },
  {
    name: 'Estonia',
    iso2_code: 'EE',
    phone_code: '+372',
  },
  {
    name: 'Eswatini',
    iso2_code: 'SZ',
    phone_code: '+268',
  },
  {
    name: 'Ethiopia',
    iso2_code: 'ET',
    phone_code: '+251',
  },
  {
    name: 'Falkland Islands',
    iso2_code: 'FK',
    phone_code: '+500',
  },
  {
    name: 'Faroe Islands',
    iso2_code: 'FO',
    phone_code: '+298',
  },
  {
    name: 'Fiji',
    iso2_code: 'FJ',
    phone_code: '+679',
  },
  {
    name: 'Finland',
    iso2_code: 'FI',
    phone_code: '+358',
  },
  {
    name: 'France',
    iso2_code: 'FR',
    phone_code: '+33',
  },
  {
    name: 'French Guiana',
    iso2_code: 'GF',
    phone_code: '+594',
  },
  {
    name: 'French Polynesia',
    iso2_code: 'PF',
    phone_code: '+689',
  },
  {
    name: 'French Southern Territories',
    iso2_code: 'TF',
    phone_code: '+262',
  },
  {
    name: 'Gabon',
    iso2_code: 'GA',
    phone_code: '+241',
  },
  {
    name: 'Gambia',
    iso2_code: 'GM',
    phone_code: '+220',
  },
  {
    name: 'Georgia',
    iso2_code: 'GE',
    phone_code: '+995',
  },
  {
    name: 'Germany',
    iso2_code: 'DE',
    phone_code: '+49',
  },
  {
    name: 'Ghana',
    iso2_code: 'GH',
    phone_code: '+233',
  },
  {
    name: 'Gibraltar',
    iso2_code: 'GI',
    phone_code: '+350',
  },
  {
    name: 'Greece',
    iso2_code: 'GR',
    phone_code: '+30',
  },
  {
    name: 'Greenland',
    iso2_code: 'GL',
    phone_code: '+299',
  },
  {
    name: 'Grenada',
    iso2_code: 'GD',
    phone_code: '+1473',
  },
  {
    name: 'Guadeloupe',
    iso2_code: 'GP',
    phone_code: '+590',
  },
  {
    name: 'Guam',
    iso2_code: 'GU',
    phone_code: '+1671',
  },
  {
    name: 'Guatemala',
    iso2_code: 'GT',
    phone_code: '+502',
  },
  {
    name: 'Guernsey',
    iso2_code: 'GG',
    phone_code: '+44',
  },
  {
    name: 'Guinea',
    iso2_code: 'GN',
    phone_code: '+224',
  },
  {
    name: 'Guinea-Bissau',
    iso2_code: 'GW',
    phone_code: '+245',
  },
  {
    name: 'Guyana',
    iso2_code: 'GY',
    phone_code: '+592',
  },
  {
    name: 'Haiti',
    iso2_code: 'HT',
    phone_code: '+509',
  },
  {
    name: 'Heard Island and McDonald Islands',
    iso2_code: 'HM',
    phone_code: '+672',
  },
  {
    name: 'Holy See',
    iso2_code: 'VA',
    phone_code: '+379',
  },
  {
    name: 'Honduras',
    iso2_code: 'HN',
    phone_code: '+504',
  },
  {
    name: 'Hong Kong',
    iso2_code: 'HK',
    phone_code: '+852',
  },
  {
    name: 'Hungary',
    iso2_code: 'HU',
    phone_code: '+36',
  },
  {
    name: 'Iceland',
    iso2_code: 'IS',
    phone_code: '+354',
  },
  {
    name: 'India',
    iso2_code: 'IN',
    phone_code: '+91',
  },
  {
    name: 'Indonesia',
    iso2_code: 'ID',
    phone_code: '+62',
  },
  {
    name: 'Iran',
    iso2_code: 'IR',
    phone_code: '+98',
  },
  {
    name: 'Iraq',
    iso2_code: 'IQ',
    phone_code: '+964',
  },
  {
    name: 'Ireland',
    iso2_code: 'IE',
    phone_code: '+353',
  },
  {
    name: 'Isle of Man',
    iso2_code: 'IM',
    phone_code: '+44',
  },
  {
    name: 'Israel',
    iso2_code: 'IL',
    phone_code: '+972',
  },
  {
    name: 'Italy',
    iso2_code: 'IT',
    phone_code: '+39',
  },
  {
    name: 'Jamaica',
    iso2_code: 'JM',
    phone_code: '+1876',
  },
  {
    name: 'Japan',
    iso2_code: 'JP',
    phone_code: '+81',
  },
  {
    name: 'Jersey',
    iso2_code: 'JE',
    phone_code: '+44',
  },
  {
    name: 'Jordan',
    iso2_code: 'JO',
    phone_code: '+962',
  },
  {
    name: 'Kazakhstan',
    iso2_code: 'KZ',
    phone_code: '+7',
  },
  {
    name: 'Kenya',
    iso2_code: 'KE',
    phone_code: '+254',
  },
  {
    name: 'Kiribati',
    iso2_code: 'KI',
    phone_code: '+686',
  },
  {
    name: 'Kosovo',
    iso2_code: 'XK',
    phone_code: '+383',
  },
  {
    name: 'Kuwait',
    iso2_code: 'KW',
    phone_code: '+965',
  },
  {
    name: 'Kyrgyzstan',
    iso2_code: 'KG',
    phone_code: '+996',
  },
  {
    name: "Lao People's Democratic Republic",
    iso2_code: 'LA',
    phone_code: '+856',
  },
  {
    name: 'Latvia',
    iso2_code: 'LV',
    phone_code: '+371',
  },
  {
    name: 'Lebanon',
    iso2_code: 'LB',
    phone_code: '+961',
  },
  {
    name: 'Lesotho',
    iso2_code: 'LS',
    phone_code: '+266',
  },
  {
    name: 'Liberia',
    iso2_code: 'LR',
    phone_code: '+231',
  },
  {
    name: 'Libya',
    iso2_code: 'LY',
    phone_code: '+218',
  },
  {
    name: 'Liechtenstein',
    iso2_code: 'LI',
    phone_code: '+423',
  },
  {
    name: 'Lithuania',
    iso2_code: 'LT',
    phone_code: '+370',
  },
  {
    name: 'Luxembourg',
    iso2_code: 'LU',
    phone_code: '+352',
  },
  {
    name: 'Macao',
    iso2_code: 'MO',
    phone_code: '+853',
  },
  {
    name: 'Madagascar',
    iso2_code: 'MG',
    phone_code: '+261',
  },
  {
    name: 'Malawi',
    iso2_code: 'MW',
    phone_code: '+265',
  },
  {
    name: 'Malaysia',
    iso2_code: 'MY',
    phone_code: '+60',
  },
  {
    name: 'Maldives',
    iso2_code: 'MV',
    phone_code: '+960',
  },
  {
    name: 'Mali',
    iso2_code: 'ML',
    phone_code: '+223',
  },
  {
    name: 'Malta',
    iso2_code: 'MT',
    phone_code: '+356',
  },
  {
    name: 'Marshall Islands',
    iso2_code: 'MH',
    phone_code: '+692',
  },
  {
    name: 'Martinique',
    iso2_code: 'MQ',
    phone_code: '+596',
  },
  {
    name: 'Mauritania',
    iso2_code: 'MR',
    phone_code: '+222',
  },
  {
    name: 'Mauritius',
    iso2_code: 'MU',
    phone_code: '+230',
  },
  {
    name: 'Mayotte',
    iso2_code: 'YT',
    phone_code: '+262',
  },
  {
    name: 'Mexico',
    iso2_code: 'MX',
    phone_code: '+52',
  },
  {
    name: 'Micronesia',
    iso2_code: 'FM',
    phone_code: '+691',
  },
  {
    name: 'Moldova',
    iso2_code: 'MD',
    phone_code: '+373',
  },
  {
    name: 'Monaco',
    iso2_code: 'MC',
    phone_code: '+377',
  },
  {
    name: 'Mongolia',
    iso2_code: 'MN',
    phone_code: '+976',
  },
  {
    name: 'Montenegro',
    iso2_code: 'ME',
    phone_code: '+382',
  },
  {
    name: 'Montserrat',
    iso2_code: 'MS',
    phone_code: '+1664',
  },
  {
    name: 'Morocco',
    iso2_code: 'MA',
    phone_code: '+212',
  },
  {
    name: 'Mozambique',
    iso2_code: 'MZ',
    phone_code: '+258',
  },
  {
    name: 'Myanmar',
    iso2_code: 'MM',
    phone_code: '+95',
  },
  {
    name: 'Namibia',
    iso2_code: 'NA',
    phone_code: '+264',
  },
  {
    name: 'Nauru',
    iso2_code: 'NR',
    phone_code: '+674',
  },
  {
    name: 'Nepal',
    iso2_code: 'NP',
    phone_code: '+977',
  },
  {
    name: 'Netherlands',
    iso2_code: 'NL',
    phone_code: '+31',
  },
  {
    name: 'New Caledonia',
    iso2_code: 'NC',
    phone_code: '+687',
  },
  {
    name: 'New Zealand',
    iso2_code: 'NZ',
    phone_code: '+64',
  },
  {
    name: 'Nicaragua',
    iso2_code: 'NI',
    phone_code: '+505',
  },
  {
    name: 'Niger',
    iso2_code: 'NE',
    phone_code: '+227',
  },
  {
    name: 'Nigeria',
    iso2_code: 'NG',
    phone_code: '+234',
  },
  {
    name: 'Niue',
    iso2_code: 'NU',
    phone_code: '+683',
  },
  {
    name: 'Norfolk Island',
    iso2_code: 'NF',
    phone_code: '+672',
  },
  {
    name: 'North Korea',
    iso2_code: 'KP',
    phone_code: '+850',
  },
  {
    name: 'North Macedonia',
    iso2_code: 'MK',
    phone_code: '+389',
  },
  {
    name: 'Northern Mariana Islands',
    iso2_code: 'MP',
    phone_code: '+1670',
  },
  {
    name: 'Norway',
    iso2_code: 'NO',
    phone_code: '+47',
  },
  {
    name: 'Oman',
    iso2_code: 'OM',
    phone_code: '+968',
  },
  {
    name: 'Pakistan',
    iso2_code: 'PK',
    phone_code: '+92',
  },
  {
    name: 'Palau',
    iso2_code: 'PW',
    phone_code: '+680',
  },
  {
    name: 'Palestine',
    iso2_code: 'PS',
    phone_code: '+970',
  },
  {
    name: 'Panama',
    iso2_code: 'PA',
    phone_code: '+507',
  },
  {
    name: 'Papua New Guinea',
    iso2_code: 'PG',
    phone_code: '+675',
  },
  {
    name: 'Paraguay',
    iso2_code: 'PY',
    phone_code: '+595',
  },
  {
    name: 'Peru',
    iso2_code: 'PE',
    phone_code: '+51',
  },
  {
    name: 'Philippines',
    iso2_code: 'PH',
    phone_code: '+63',
  },
  {
    name: 'Pitcairn',
    iso2_code: 'PN',
    phone_code: '+64',
  },
  {
    name: 'Poland',
    iso2_code: 'PL',
    phone_code: '+48',
  },
  {
    name: 'Portugal',
    iso2_code: 'PT',
    phone_code: '+351',
  },
  {
    name: 'Puerto Rico',
    iso2_code: 'PR',
    phone_code: '+1939',
  },
  {
    name: 'Qatar',
    iso2_code: 'QA',
    phone_code: '+974',
  },
  {
    name: 'Romania',
    iso2_code: 'RO',
    phone_code: '+40',
  },
  {
    name: 'Russian Federation',
    iso2_code: 'RU',
    phone_code: '+7',
  },
  {
    name: 'Rwanda',
    iso2_code: 'RW',
    phone_code: '+250',
  },
  {
    name: 'Réunion',
    iso2_code: 'RE',
    phone_code: '+262',
  },
  {
    name: 'Saint Barthélemy',
    iso2_code: 'BL',
    phone_code: '+590',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    iso2_code: 'SH',
    phone_code: '+290',
  },
  {
    name: 'Saint Kitts and Nevis',
    iso2_code: 'KN',
    phone_code: '+1869',
  },
  {
    name: 'Saint Lucia',
    iso2_code: 'LC',
    phone_code: '+1758',
  },
  {
    name: 'Saint Martin',
    iso2_code: 'MF',
    phone_code: '+590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    iso2_code: 'PM',
    phone_code: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso2_code: 'VC',
    phone_code: '+1784',
  },
  {
    name: 'Samoa',
    iso2_code: 'WS',
    phone_code: '+685',
  },
  {
    name: 'San Marino',
    iso2_code: 'SM',
    phone_code: '+378',
  },
  {
    name: 'Sao Tome and Principe',
    iso2_code: 'ST',
    phone_code: '+239',
  },
  {
    name: 'Saudi Arabia',
    iso2_code: 'SA',
    phone_code: '+966',
  },
  {
    name: 'Senegal',
    iso2_code: 'SN',
    phone_code: '+221',
  },
  {
    name: 'Serbia',
    iso2_code: 'RS',
    phone_code: '+381',
  },
  {
    name: 'Seychelles',
    iso2_code: 'SC',
    phone_code: '+248',
  },
  {
    name: 'Sierra Leone',
    iso2_code: 'SL',
    phone_code: '+232',
  },
  {
    name: 'Singapore',
    iso2_code: 'SG',
    phone_code: '+65',
  },
  {
    name: 'Sint Maarten',
    iso2_code: 'SX',
    phone_code: '+1721',
  },
  {
    name: 'Slovakia',
    iso2_code: 'SK',
    phone_code: '+421',
  },
  {
    name: 'Slovenia',
    iso2_code: 'SI',
    phone_code: '+386',
  },
  {
    name: 'Solomon Islands',
    iso2_code: 'SB',
    phone_code: '+677',
  },
  {
    name: 'Somalia',
    iso2_code: 'SO',
    phone_code: '+252',
  },
  {
    name: 'South Africa',
    iso2_code: 'ZA',
    phone_code: '+27',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    iso2_code: 'GS',
    phone_code: '+500',
  },
  {
    name: 'South Korea',
    iso2_code: 'KR',
    phone_code: '+82',
  },
  {
    name: 'South Sudan',
    iso2_code: 'SS',
    phone_code: '+211',
  },
  {
    name: 'Spain',
    iso2_code: 'ES',
    phone_code: '+34',
  },
  {
    name: 'Sri Lanka',
    iso2_code: 'LK',
    phone_code: '+94',
  },
  {
    name: 'Sudan',
    iso2_code: 'SD',
    phone_code: '+249',
  },
  {
    name: 'Suriname',
    iso2_code: 'SR',
    phone_code: '+597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    iso2_code: 'SJ',
    phone_code: '+47',
  },
  {
    name: 'Sweden',
    iso2_code: 'SE',
    phone_code: '+46',
  },
  {
    name: 'Switzerland',
    iso2_code: 'CH',
    phone_code: '+41',
  },
  {
    name: 'Syria',
    iso2_code: 'SY',
    phone_code: '+963',
  },
  {
    name: 'Taiwan',
    iso2_code: 'TW',
    phone_code: '+886',
  },
  {
    name: 'Tajikistan',
    iso2_code: 'TJ',
    phone_code: '+992',
  },
  {
    name: 'Tanzania',
    iso2_code: 'TZ',
    phone_code: '+255',
  },
  {
    name: 'Thailand',
    iso2_code: 'TH',
    phone_code: '+66',
  },
  {
    name: 'Timor-Leste',
    iso2_code: 'TL',
    phone_code: '+670',
  },
  {
    name: 'Togo',
    iso2_code: 'TG',
    phone_code: '+228',
  },
  {
    name: 'Tokelau',
    iso2_code: 'TK',
    phone_code: '+690',
  },
  {
    name: 'Tonga',
    iso2_code: 'TO',
    phone_code: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    iso2_code: 'TT',
    phone_code: '+1868',
  },
  {
    name: 'Tunisia',
    iso2_code: 'TN',
    phone_code: '+216',
  },
  {
    name: 'Turkey',
    iso2_code: 'TR',
    phone_code: '+90',
  },
  {
    name: 'Turkmenistan',
    iso2_code: 'TM',
    phone_code: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    iso2_code: 'TC',
    phone_code: '+1649',
  },
  {
    name: 'Tuvalu',
    iso2_code: 'TV',
    phone_code: '+688',
  },
  {
    name: 'USA Minor Outlying Islands',
    iso2_code: 'UM',
    phone_code: '+699',
  },
  {
    name: 'Uganda',
    iso2_code: 'UG',
    phone_code: '+256',
  },
  {
    name: 'Ukraine',
    iso2_code: 'UA',
    phone_code: '+380',
  },
  {
    name: 'United Arab Emirates',
    iso2_code: 'AE',
    phone_code: '+971',
  },
  {
    name: 'United Kingdom',
    iso2_code: 'GB',
    phone_code: '+44',
  },
  {
    name: 'United States',
    iso2_code: 'US',
    phone_code: '+1',
  },
  {
    name: 'United States Virgin Islands',
    iso2_code: 'VI',
    phone_code: '+1340',
  },
  {
    name: 'Uruguay',
    iso2_code: 'UY',
    phone_code: '+598',
  },
  {
    name: 'Uzbekistan',
    iso2_code: 'UZ',
    phone_code: '+998',
  },
  {
    name: 'Vanuatu',
    iso2_code: 'VU',
    phone_code: '+678',
  },
  {
    name: 'Venezuela',
    iso2_code: 'VE',
    phone_code: '+58',
  },
  {
    name: 'Vietnam',
    iso2_code: 'VN',
    phone_code: '+84',
  },
  {
    name: 'Wallis and Futuna',
    iso2_code: 'WF',
    phone_code: '+681',
  },
  {
    name: 'Western Sahara',
    iso2_code: 'EH',
    phone_code: '+212',
  },
  {
    name: 'Yemen',
    iso2_code: 'YE',
    phone_code: '+967',
  },
  {
    name: 'Zambia',
    iso2_code: 'ZM',
    phone_code: '+260',
  },
  {
    name: 'Zimbabwe',
    iso2_code: 'ZW',
    phone_code: '+263',
  },
]

