/* Built In Imports */
import { useMemo, useRef, useState } from 'react';

/* External Imports */
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { CgAttachment } from 'react-icons/cg';
import SignatureCanvas from 'react-signature-canvas';

/* Internal Imports */
/* Components */
import { phoneWithoutChinaCode } from '@constants/phoneWithCountryCode';
import { EnrollSectionSt } from '@ieo/Common/CompLangConfig';
import { stateList } from '@ieo/Components/Form/ieo/constants/stateList';
import { useLanguage } from '@ieo/Components/Form/ieo/context/languageContext';
import { useIsIndia } from '@ieo/Components/Form/ieo/context/regionContext';
import { Trans, useTranslation } from '@ieo/Components/Form/ieo/translation';

/* Services */

const getErrorMessage = error => {
  if (!error) return null;

  if (error.message) return error.message;

  switch (error.type) {
    case 'required':
      return <Trans i18nKey={'validationMessages.required'} />;
    case 'minLength':
      return <Trans i18nKey={'validationMessages.minLengthGeneric'} />;
    case 'maxLength':
      return <Trans i18nKey={'validationMessages.maxLength'} />;
    default:
      return error.message || 'Invalid';
  }
};

const requiredLabel = (label, isRequired) => {
  return `${label} ${isRequired ? '*' : ''}`;
};

export const HookFormTextField = ({
  name,
  label,
  required,
  rules,
  testId,
  defaultValue = '',
  disabled,
  padding,
  disablePlaceholder,
  ...props
}) => {
  const lang = useLanguage();
  // console.log(required, rules);
  return (
    <Controller
      name={name}
      rules={{ required, ...rules }}
      defaultValue={defaultValue}
      render={({ field: { ref, ...rest }, fieldState }) => {
        const hasError = !!fieldState.error;

        return (
          <FormControl isInvalid={hasError}>
            <Input
              ref={ref}
              type="text"
              placeholder={
                !disablePlaceholder && requiredLabel(label, required)
              }
              _placeholder={{ color: '#9B9685' }}
              color="#231F20"
              borderRadius="0px"
              bg="#F1EBDF"
              border={'solid 1px'}
              borderColor={'#F1EBDF'}
              _hover={{
                borderColor: '#F1EBDF',
              }}
              _focus={{
                borderColor: '#F1EBDF',
              }}
              p={padding || '16px'}
              isInvalid={hasError}
              errorBorderColor={'#FF0000'}
              isDisabled={disabled}
              data-test-id={testId}
              {...EnrollSectionSt[lang]?.input}
              {...rest}
              {...props}
            />
            <FormErrorMessage
              dir={lang == 'ar' ? 'rtl' : 'ltr'}
              style={{ display: hasError ? 'block' : 'none' }}
              sx={{
                marginLeft: '0px',
              }}
            >
              {getErrorMessage(fieldState.error)}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export const HookFormSelectField = ({
  name,
  label,
  options,
  required,
  disabled,
  rules,
  defaultValue = '',
  testId,
  disablePlaceholder,
  lang,
  ...props
}) => {
  return (
    <Controller
      name={name}
      rules={{ required, ...rules }}
      defaultValue={defaultValue}
      render={({ field: { ref, ...rest }, fieldState }) => {
        const hasError = !!fieldState.error;
        const id = 'form-' + name;

        return (
          <FormControl isInvalid={hasError}>
            <Select
              id={id}
              data-test-id={testId}
              required={required}
              isDisabled={disabled}
              name={name}
              placeholder={requiredLabel(label, required)}
              ref={ref}
              isInvalid={hasError}
              color={'#5B5237'}
              borderRadius="0px"
              bg="#F1EBDF"
              border={'solid 1px'}
              borderColor={'#F1EBDF'}
              _hover={{
                borderColor: '#F1EBDF',
              }}
              _focus={{
                borderColor: '#F1EBDF',
              }}
              errorBorderColor={'#FF0000'}
              {...rest}
              {...props}
            >
              {options}
            </Select>
            <FormErrorMessage
              dir={lang == 'ar' ? 'rtl' : 'ltr'}
              style={{ display: hasError ? 'block' : 'none' }}
              sx={{
                marginLeft: '0px',
              }}
            >
              {getErrorMessage(fieldState.error)}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export const HookFormStateSelectField = ({ country, ...props }) => {
  const renderDropdown = country in stateList;
  const stateOptions = useMemo(
    () =>
      renderDropdown
        ? [...stateList[country]].map(item => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))
        : undefined,
    [country, renderDropdown]
  );

  return renderDropdown ? (
    <HookFormSelectField options={stateOptions} {...props} />
  ) : (
    <HookFormTextField {...props} />
  );
};

export const HookFormLanguageSelectField = ({ langList, ...props }) => {
  const langListOption = Object.entries(langList).map(([key, value]) => (
    <option key={key} value={key}>
      {value}
    </option>
  ));

  return langList.length > 0 ? (
    <HookFormSelectField options={langListOption} {...props} />
  ) : (
    <HookFormTextField {...props} />
  );
};

export const HookFormCheckboxField = ({
  name,
  label,
  required,
  defaultValue = false,
  testId,
  disabled,
  validationMessage,
  lang,
  alignItems,
  ...props
}) => {
  return (
    <Controller
      name={name}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field: { ref, value, ...rest }, fieldState }) => {
        const hasError = !!fieldState.error;

        return (
          <FormControl
            dir={lang == 'ar' && 'rtl'}
            isInvalid={hasError}
            style={{ textAlign: lang == 'ar' && 'right' }}
          >
            <Checkbox
              colorScheme="orange"
              isChecked={value}
              data-test-id={testId}
              isDisabled={disabled}
              {...props}
              {...rest}
              ref={ref}
              borderColor={value ? 'orange' : '#E86F34'}
              fontFamily="'FedraSansStd-book'"
              color="#433F3C"
              alignItems={alignItems || 'flex-start'}
              style={{ textAlign: lang == 'ar' && 'right' }}
              display={lang == 'ar' ? 'flex' : 'flex'}
              align={'top'}
            >
              {label}
            </Checkbox>
            <FormErrorMessage
              dir={lang == 'ar' ? 'rtl' : 'ltr'}
              style={{ display: hasError ? 'block' : 'none' }}
              sx={{
                marginLeft: '0px',
              }}
            >
              {validationMessage || getErrorMessage(fieldState.error)}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export const HookRadioGroupField = ({
  name,
  label,
  required,
  defaultValue = false,
  testId,
  disabled,
  validationMessage,
  lang,
  ...props
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(t('formFields?.armyServingDecl'));

  return (
    <Controller
      name={name}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field: { ref, value, ...rest }, fieldState }) => {
        const hasError = !!fieldState.error;

        return (
          <FormControl
            dir={lang == 'ar' && 'rtl'}
            isInvalid={hasError}
            style={{ textAlign: lang == 'ar' && 'right' }}
          >
            <RadioGroup
              name="declaration_data.type"
              onChange={setValue}
              value={value}
            >
              <Stack direction="column">
                <Radio
                  value={t('formFields?.armyServingDecl')}
                  colorScheme="orange"
                >
                  {t('transProviderObj?.formFields?.armyServingDecl')}
                </Radio>
                <Radio
                  value={t('transProviderObj?.formFields?.armyDependentDecl')}
                  colorScheme="orange"
                >
                  {t('transProviderObj?.formFields?.armyDependentDecl')}
                </Radio>
              </Stack>
            </RadioGroup>
            <FormErrorMessage
              dir={lang == 'ar' ? 'rtl' : 'ltr'}
              style={{ display: hasError ? 'block' : 'none' }}
              sx={{
                marginLeft: '0px',
              }}
            >
              {validationMessage || getErrorMessage(fieldState.error)}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export const HookFormHiddenField = ({
  name,
  testId,
  defaultValue = '',
  ...props
}) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...rest }, fieldState }) => {
        return (
          <FormControl isInvalid={false}>
            <Input
              ref={ref}
              type="hidden"
              isInvalid={false}
              data-test-id={testId}
              {...rest}
              {...props}
            />
          </FormControl>
        );
      }}
    />
  );
};

const options = [
  <option value={'+91'} key={'in'}>
    +91 India
  </option>,
  <option value={'+1'} key={'us-ca'}>
    +1 US and Canada
  </option>,
  ...phoneWithoutChinaCode.map((item, index) => (
    <option value={item.phone_code} key={index}>
      {item.phone_code} {item.name}
    </option>
  )),
];

export const HookFormPhoneNumberField = ({
  name,
  countryFieldName,
  disableCountryField,
  disabled,
  label,
  required,
  testId,
  invalidMessage,
  lang,
}) => {
  const { t } = useTranslation();
  const isIndia = useIsIndia();

  return (
    <Box
      data-test-id={testId}
      display={'flex'}
      dir={lang == 'ar' ? 'rtl' : 'ltr'}
    >
      <HookFormSelectField
        name={countryFieldName}
        placeholder={t('formFields.countryCode')}
        required={required}
        options={options}
        width="100%"
        disabled={disableCountryField || disabled}
        dir={lang == 'ar' ? 'rtl' : 'ltr'}
      />

      <HookFormTextField
        dir={lang == 'ar' ? 'rtl' : 'ltr'}
        name={name}
        label={label}
        required={required}
        type={'tel'}
        padding="0px 10px 0px 0px"
        minW={{ base: 'auto', md: '150px' }}
        rules={{
          pattern: {
            value: /^\d*$/,
            message: invalidMessage,
          },
          ...(isIndia
            ? {
                minLength: 10,
                maxLength: 10,
              }
            : {
                minLength: 6,
                maxLength: 15,
              }),
        }}
        disabled={disabled}
      />
    </Box>
  );
};

export const HookFormNumberField = ({
  name,
  disabled,
  label,
  required,
  testId,
  invalidMessage,
  lang,
}) => {
  const { t } = useTranslation();
  const isIndia = useIsIndia();

  return (
    <Box data-test-id={testId} display={'flex'}>
      <HookFormTextField
        name={name}
        label={label}
        required={required}
        type={'number'}
        padding="0px 10px 0px 15px"
        minW={{ base: 'auto', md: '150px' }}
        rules={{
          pattern: {
            value: /^\d*$/,
            message: invalidMessage,
          },
          ...(isIndia
            ? {
                minLength: 10,
                maxLength: 10,
              }
            : {
                minLength: 6,
                maxLength: 15,
              }),
        }}
      />
    </Box>
  );
};

export const HookFormScrollableTermsField = ({
  name,
  heading,
  subHeading,
  content,
  textAlign = 'left',
}) => {
  const { t } = useTranslation();
  const scrollBoxRef = useRef(null);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const lang = useLanguage();

  const onScroll = () => {
    const element = scrollBoxRef.current;
    if (
      Math.abs(
        element.scrollHeight - element.scrollTop - element.clientHeight
      ) < 10
    ) {
      setScrolledToBottom(true);
    }
  };

  return (
    <>
      <Box
        fontFamily="FedraSansStd-medium"
        color="#CF4520"
        fontSize="14px"
        dir={lang == 'ar' ? 'rtl' : 'ltr'}
      >
        {heading}
      </Box>
      <Box
        fontFamily="FedraSansStd-book"
        fontSize="12px"
        mt="6px !important"
        dir={lang == 'ar' ? 'rtl' : 'ltr'}
      >
        {subHeading || ''}
      </Box>

      <Box bgColor={'#FFF9EE'} px={6} py={4}>
        <Box
          maxHeight={'130px'}
          overflow={'auto'}
          mb={4}
          ref={scrollBoxRef}
          onScroll={scrolledToBottom ? undefined : onScroll}
          fontFamily="FedraSansStd-book"
          fontWeight="400"
          fontSize="14px"
          textAlign={lang == 'ar' ? 'right' : textAlign}
          className="tc-content"
          dir={lang == 'ar' ? 'rtl' : 'ltr'}
        >
          {content}
        </Box>
        <HookFormCheckboxField
          name={name}
          label={t('formFields.agree')}
          required={true}
          disabled={!scrolledToBottom}
          validationMessage={t('validationMessages.legalWaiver.required')}
          dir={lang == 'ar' ? 'rtl' : 'ltr'}
        />
      </Box>
      <style>
        {`.tc-content p {
            margin: 10px 0;
          }
          .tc-content mark {
            color: #CF4520;
            font-family: 'FedraSansStd-medium';
            background: none;
          }
          `}
      </style>
    </>
  );
};

export const HookFormFileUploadField = ({
  name,
  label,
  required,
  rules,
  testId,
  defaultValue = '',
  disabled,
  padding,
  resetField,
  ...props
}) => {
  const lang = useLanguage();
  const { t } = useTranslation();
  const [youthParentAadhaar, setYouthParentAadhaar] = useState(null);
  // console.log(required, rules);
  // useEffect(() => {
  //   console.log('youthParentAadhaar', youthParentAadhaar);
  // }, [youthParentAadhaar]);

  return (
    <Controller
      name={name}
      rules={{ required, ...rules }}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, ...field }, fieldState }) => {
        const hasError = !!fieldState.error && !disabled;
        // console.log('hasError', fieldState);
        return (
          <FormControl isInvalid={hasError}>
            {!youthParentAadhaar && (
              <Box pos="relative">
                <Input
                  value={value?.fileName}
                  onChange={event => {
                    onChange(event.target.files[0]);
                    setYouthParentAadhaar(event.target.files[0]);
                  }}
                  type="file"
                  id="file-upload"
                  {...EnrollSectionSt[lang]?.input}
                  isInvalid={hasError}
                  isDisabled={disabled}
                  data-test-id={testId}
                  {...field}
                  {...props}
                  display="none"
                />
                <label htmlFor="file-upload">
                  <Box
                    height="40px"
                    bg="#F1EBDF"
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    top="0"
                  >
                    <Box
                      fontWeight="light"
                      display="flex"
                      justifyContent="flex-start"
                      fontSize="16px"
                      fontFamily="'FedraSansStd-book'"
                      px="18px"
                      py="8px"
                      color="#9b9685"
                    >
                      {t(props.placeholder || 'formFields.youthParentAadhar')}
                    </Box>
                    <Box
                      w={{ base: '30px', md: '50px' }}
                      bg="#F37021"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      h="full"
                      cursor="pointer"
                    >
                      <CgAttachment color="white" />
                    </Box>
                  </Box>
                </label>

                {!getErrorMessage(fieldState.error) && (
                  <Box
                    fontFamily="'FedraSansStd-book'"
                    fontSize={{ base: '12px', md: '14px' }}
                    lineHeight="136%"
                    color="#F37021"
                    pt="10px"
                    pl={{ base: '0', md: '5px' }}
                  >
                    {t('validationMessages.youthParentAadharCard.default')}
                  </Box>
                )}
              </Box>
            )}

            {youthParentAadhaar && (
              <Flex
                fontFamily="'FedraSansStd-book'"
                fontSize="14px"
                color="#F37021"
                p="10px"
                background="#F1EBDF"
                h="53px"
                justifyContent="space-between"
              >
                <Tag
                  size="lg"
                  borderRadius="full"
                  variant="solid"
                  bgColor="white"
                  color="#5B5237"
                >
                  <TagLabel>{youthParentAadhaar?.name}</TagLabel>
                  <TagCloseButton
                    bgColor="#433F3C"
                    color="white"
                    cursor="pointer"
                    onClick={event => {
                      resetField(name);
                      setYouthParentAadhaar(null);
                    }}
                  />
                </Tag>
                <CgAttachment
                  color="#3652B8"
                  fontSize="28px"
                  cursor="pointer"
                  onClick={event => {
                    setYouthParentAadhaar(null);
                  }}
                />
              </Flex>
            )}

            <FormErrorMessage
              dir={lang == 'ar' ? 'rtl' : 'ltr'}
              style={{ display: hasError ? 'block' : 'none' }}
              sx={{
                marginLeft: '0',
              }}
            >
              {getErrorMessage(fieldState.error)}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export const HookFormSignatureField = ({
  name,
  label,
  required,
  rules,
  testId,
  defaultValue = '',
  disabled,
  padding,
  ...props
}) => {
  const { t } = useTranslation();
  const [isCanvasVis, setIsCanvasVis] = useState(false);

  const sigRef = useRef();
  const handleSignatureEnd = () => {
    setParentSignature(sigRef.current.toDataURL());
  };
  const clearSignature = () => {
    sigRef.current.clear();
    setParentSignature(null);
  };

  return (
    <Controller
      name={name}
      rules={{ required, ...rules }}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, ...field }, fieldState }) => {
        const hasError = !!fieldState.error && !disabled;

        return (
          <>
            <FormControl isInvalid={hasError}>
              {!isCanvasVis && (
                <Box
                  position="relative"
                  maxW="302.91px"
                  w="100%"
                  height="131px"
                  bg="#F2F2F2"
                  border="1px dashed #8C8080"
                  borderRadius="4px"
                  cursor="pointer"
                  onClick={() => {
                    setIsCanvasVis(true);
                  }}
                >
                  <Box
                    fontFamily="'FedraSansStd-book'"
                    fontSize="16px"
                    lineHeight="136%"
                    color="#4872DF"
                    py="10px"
                    pos="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  >
                    Click to sign
                  </Box>
                </Box>
              )}
              {isCanvasVis && (
                <Box
                  width="300px"
                  height="130px"
                  border="1px dashed #8C8080"
                  borderRadius="4px"
                >
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 300,
                      height: 130,
                      className: 'sigCanvas',
                    }}
                    ref={sigRef}
                    onEnd={handleSignatureEnd}
                  />
                  <Box onClick={clearSignature} cursor="pointer">
                    Clear Signature
                  </Box>
                </Box>
              )}
              <FormErrorMessage
                // dir={lang == 'ar' ? 'rtl' : 'ltr'}
                style={{ display: hasError ? 'block' : 'none' }}
                sx={{
                  marginLeft: '0',
                }}
              >
                {getErrorMessage(fieldState.error)}
              </FormErrorMessage>
            </FormControl>
          </>
        );
      }}
    />
  );
};
