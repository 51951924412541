import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';
const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h2: {
      fontSize: {
        base: 28,
        md: 44,
        lg: 64,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      lineHeight: { base: '32px', md: '62.08px' },
    },

    subTitle: {
      fontSize: {
        base: 20,
        md: 32,
      },
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
      color: '#fff',
      textTransform: 'uppercase',
    },

    p: {
      fontSize: {
        base: 16,
        md: 22,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },

    button: {
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      fontSize: 18,
    },
    or: {
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
      fontSize: {
        base: 18,
        md: 22,
      },
    },
    enrollIn: {
      fontSize: {
        base: 18,
        md: 20,
      },
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
    },
    price: {
      fontSize: {
        base: 20,
        md: 32,
      },
      fontFamily: fontFamily,
    },
    input: {
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const EnrollSection = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({
    lang: 'ar',
    h2: {
      fontFamily: "'Almarai', sans-serif",
      lineHeight: { base: '32px', md: '1.3em' },
    },

    subTitle: {
      fontFamily: "'Almarai', sans-serif",
    },

    p: {
      fontFamily: "'Almarai', sans-serif",
    },

    button: {
      fontFamily: "'Almarai', sans-serif",
    },
    or: {
      fontFamily: "'Almarai', sans-serif",
    },
    enrollIn: {
      fontFamily: "'Almarai', sans-serif",
    },
    input: {
      fontFamily: "'Almarai', sans-serif",
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default EnrollSection;
