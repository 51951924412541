/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { CountryWithoutChina } from '@constants/countryCodes';
import { usePageType } from '@hooks/usePageType';
import { gtmObject } from '@ieo/Components/Form/ieo/api/analyticsAPi';
import { useIsIndia } from '@ieo/Components/Form/ieo/context/regionContext';
import { IeoFormFieldGrid } from '@ieo/Components/Form/ieo/elements/formComponents';
import { useTranslation } from '@ieo/Components/Form/ieo/translation';
import { render } from 'datocms-structured-text-to-html-string';
import {
  FormErrorMessage,
  SubmitButton,
  blankSpaceValidation,
  canteenCardCharactersValidation,
  emailCharactersValidation,
  englishCharactersValidation,
} from './formElements';
import {
  HookFormPhoneNumberField,
  HookFormSelectField,
  HookFormTextField,
} from './formFields';

/* Services */
/* Styles */

const countryOptions = CountryWithoutChina.map(item => (
  <option value={item.value} key={item.value}>
    {item.label}
  </option>
));

export function Step1FormFields({
  disableCountryPicker,
  disableFirstLastEmailFields,
  hasPrefilledFirstLastName,
  pageName,
  lang,
  pageData,
  setValue,
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const isIndia = useIsIndia();
  const isArmyOfferURL = router.asPath.includes('armed-forces');

  const transProviderObj =
    (pageData._allSharedJsonLocales?.length &&
      pageData._allSharedJsonLocales[0]?.value.json) ||
    pageData.sharedJson?.json;

  const [armyDeclarationType, setArmyDeclarationType] = useState(
    transProviderObj?.formFields?.armyServingDecl
  );

  useEffect(() => {
    setValue('declaration_data.type', armyDeclarationType);
  }, [armyDeclarationType]);

  const dependantRelationList = transProviderObj?.formFields?.depandantRelation
    ?.split(',')
    ?.map(item => (
      <option value={item} key={item}>
        {item}
      </option>
    ));

  return (
    <>
      <IeoFormFieldGrid>
        {lang == 'ar' ? (
          <>
            <HookFormTextField
              dir="rtl"
              name={'lname'}
              label={t('formFields.lastName')}
              disabled={disableFirstLastEmailFields}
              required
              lang={lang}
              rules={{
                required: t('validationMessages.lastName.required'),
                validate: {
                  blankSpace: value =>
                    blankSpaceValidation.value.test(value) ||
                    t(blankSpaceValidation.message),
                  englishCharacters: value =>
                    isIndia
                      ? englishCharactersValidation.value.test(value) ||
                        t(englishCharactersValidation.message)
                      : null,
                },
                maxLength: 100,
                minLength: 1,
              }}
              testId={'lname-input'}
            />
            <HookFormTextField
              dir="rtl"
              name={'fname'}
              label={t('formFields.firstName')}
              disabled={disableFirstLastEmailFields}
              required
              testId={'fname-input'}
              lang={lang}
              rules={{
                required: t('validationMessages.firstName.required'),
                validate: {
                  blankSpace: value =>
                    blankSpaceValidation.value.test(value) ||
                    t(blankSpaceValidation.message),
                  englishCharacters: value =>
                    isIndia
                      ? englishCharactersValidation.value.test(value) ||
                        t(englishCharactersValidation.message)
                      : null,
                },
                maxLength: 100,
                minLength: isIndia ? 3 : 1,
              }}
            />
          </>
        ) : (
          <>
            <HookFormTextField
              id="form-fname-input"
              name={'fname'}
              label={t('formFields.firstName')}
              disabled={disableFirstLastEmailFields}
              required
              testId={'fname-input'}
              lang={lang}
              rules={{
                required: t('validationMessages.firstName.required'),
                validate: {
                  blankSpace: value =>
                    blankSpaceValidation.value.test(value) ||
                    t(blankSpaceValidation.message),
                  englishCharacters: value =>
                    isIndia
                      ? englishCharactersValidation.value.test(value) ||
                        t(englishCharactersValidation.message)
                      : null,
                },
                maxLength: 100,
                minLength: isIndia ? 3 : 1,
              }}
            />

            <HookFormTextField
              id="form-lname-input"
              name={'lname'}
              label={t('formFields.lastName')}
              disabled={disableFirstLastEmailFields}
              required
              lang={lang}
              rules={{
                required: t('validationMessages.lastName.required'),
                validate: {
                  blankSpace: value =>
                    blankSpaceValidation.value.test(value) ||
                    t(blankSpaceValidation.message),
                  englishCharacters: value =>
                    isIndia
                      ? englishCharactersValidation.value.test(value) ||
                        t(englishCharactersValidation.message)
                      : null,
                },
                maxLength: 100,
                minLength: 1,
              }}
              testId={'lname-input'}
            />
          </>
        )}
        <HookFormTextField
          id="form-email-input"
          dir={lang == 'ar' ? 'rtl' : 'ltr'}
          name={'email'}
          label={t('formFields.email')}
          disabled={disableFirstLastEmailFields}
          type={'email'}
          required
          rules={{
            pattern: {
              value: emailCharactersValidation.value,
              message: t(emailCharactersValidation.message),
            },
            maxLength: 120,
          }}
          testId={'email-input'}
        />
        <HookFormPhoneNumberField
          id="form-pCountry-input"
          dir={lang == 'ar' ? 'rtl' : 'ltr'}
          name={'pPhone'}
          countryFieldName={'pCountry'}
          disableCountryField={isIndia}
          label={t('formFields.phoneNumber')}
          required={pageName === 'corporate-register' ? false : true}
          invalidMessage={t('validationMessages.phoneNumber.invalid')}
          lang={lang}
        />
        <HookFormSelectField
          name={'country'}
          label={t('formFields.country')}
          required
          disabled={disableCountryPicker}
          options={countryOptions}
          testId={'country-input'}
        />
        {isArmyOfferURL && (
          <HookFormTextField
            name={'canteenCardNumber'}
            label={t('formFields.canteenCardNumber')}
            invalidMessage={t('validationMessages.canteenCardNumber.invalid')}
            lang={lang}
            type={'text'}
            required
            rules={{
              pattern: {
                value: canteenCardCharactersValidation.value,
                message: t(canteenCardCharactersValidation.message),
              },
              maxLength: 19,
            }}
          />
        )}
      </IeoFormFieldGrid>
    </>
  );
}

export function Step1Form({
  onSubmit,
  errorMessage,
  isSubmitting,
  disableCountryPicker,
  pageData,
  lang,
  disableFirstLastEmailFields,
  hasPrefilledFirstLastName,
  isTransfer,
  program,
  region,
  isReducedFee,
  updatedProgramFee,
  originalProgramFee,
  setValue,
}) {
  const { t } = useTranslation();
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const { trigger } = useFormContext();
  // const getLanguageName = code => t(`languages.${code.toLowerCase()}`);
  const { isGiftPage } = usePageType();
  const eoeRegister =
    pageData?.body?.length > 0 &&
    pageData.body[0]?.value?.length > 14 &&
    pageData.body[0].value[14].sectionContent
      ? pageData.body[0].value[14].sectionContent.find(
          e => e.fieldId === 'eoe-register'
        )
      : null;

  const gtmClick = () => {
    gtmObject({
      program,
      disableFirstLastEmailFields,
      isReducedFee,
      updatedProgramFee,
      originalProgramFee,
      pageData,
      item_name: isGiftPage
        ? 'Gift Inner Engineering'
        : 'TYLS Inner Engineering',
      event: isGiftPage ? 'IE_Enroll_Gift' : 'IE_Enroll',
      event1: 'add_to_cart',
    });
  };

  return (
    <Box px={{ base: '14px', md: '40px' }} mb={{ base: '30px', md: '70px' }}>
      <form onSubmit={onSubmit}>
        <Step1FormFields
          disableCountryPicker={disableCountryPicker}
          disableFirstLastEmailFields={disableFirstLastEmailFields}
          hasPrefilledFirstLastName={hasPrefilledFirstLastName}
          lang={lang}
          pageData={pageData}
          setValue={setValue}
        />

        <Box mt={{ base: 6, md: 8 }}>
          {errorMessage && clickedSubmit && !isSubmitting && (
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          )}
          <SubmitButton
            label={t(isTransfer ? 'formFields.continue' : 'formFields.enroll')}
            isSubmitting={isSubmitting}
            testId={'step1-submit'}
            onClick={() => {
              // Show validation messages for all form fields
              trigger();
              setClickedSubmit(true);
              gtmClick();
            }}
          />
        </Box>
      </form>
      {eoeRegister && (region === 'us' || region === 'ca') && (
        <Box pt={7}>
          <StructuredTextParser
            str={render(eoeRegister?.body)}
            alignItems="center"
            color="#000"
            fontFamily="'FedraSansStd-book'"
            mt="0px"
            fontSize={{ base: '14px', md: '16px' }}
            className="pAtext"
            maxW="100%"
          />
        </Box>
      )}

      <style jsx global>
        {`
          .pAtext p a {
            color: #3652b8 !important;
            text-decoration: underline;
          }
          .pAtext p a:hover {
            text-decoration: none;
          }
        `}
      </style>
    </Box>
  );
}
