import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h2: {
      fontSize: {
        base: 32,
        md: 44,
        xl: 64,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      lineHeight: { base: '32px', md: '42.6px', xl: '62px' },
      color: '#3B444B',
    },
    h4: {
      fontSize: {
        base: 20,
        md: 26,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      color: '#12166F',
    },
    h6: {
      fontSize: {
        base: 18,
        md: 20,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
    p: {
      fontSize: {
        base: 14,
        sm: 16,
      },
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const ProgramStructureSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ar: commonLangStyle({
    lang: 'ar',
    h2: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: { base: 'right', lg: 'left' },
    },
    h4: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: { base: 'right', lg: 'center' },
    },
    h6: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    p: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
  }),
  ta: commonLangStyle({
    lang: 'ta',
    p: {
      fontSize: {
        base: 16,
        sm: 16,
      },
      textAlign: 'left',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default ProgramStructureSt;
