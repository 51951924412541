import React, { useContext } from 'react';

export const SectionContentContext = React.createContext(undefined);

export const useSectionContent = () => {
  const contextValue = useContext(SectionContentContext);

  if (!contextValue)
    throw new Error(
      'useSectionContent needs to be used with SectionContentContext'
    );

  return contextValue;
};
