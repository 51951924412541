export const stateList = [
  {
    country_id: 'CA',
    state_id: 'AB',
    state_name: 'Alberta',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'YT',
    state_name: 'Yukon',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'SK',
    state_name: 'Saskatchewan',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'QC',
    state_name: 'Quebec',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'PE',
    state_name: 'Prince Edward Island',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'ON',
    state_name: 'Ontario',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'NU',
    state_name: 'Nunavut',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'NS',
    state_name: 'Nova Scotia',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'BC',
    state_name: 'British Columbia',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'MB',
    state_name: 'Manitoba',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'NB',
    state_name: 'New Brunswick',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'NL',
    state_name: 'Newfoundland and Labrador',
    display_status: '1',
  },
  {
    country_id: 'CA',
    state_id: 'NT',
    state_name: 'Northwest Territories',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'OR',
    state_name: 'Oregon',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'OK',
    state_name: 'Oklahoma',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'OH',
    state_name: 'Ohio',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'ND',
    state_name: 'North Dakota',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'NC',
    state_name: 'North Carolina',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'NY',
    state_name: 'New York',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'NM',
    state_name: 'New Mexico',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'NJ',
    state_name: 'New Jersey',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'NH',
    state_name: 'New Hampshire',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'NV',
    state_name: 'Nevada',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'NE',
    state_name: 'Nebraska',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'PA',
    state_name: 'Pennsylvania',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'RI',
    state_name: 'Rhode Island',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'WY',
    state_name: 'Wyoming',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'WI',
    state_name: 'Wisconsin',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'WV',
    state_name: 'West Virginia',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'WA',
    state_name: 'Washington',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'VA',
    state_name: 'Virginia',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'VT',
    state_name: 'Vermont',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'UT',
    state_name: 'Utah',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'TX',
    state_name: 'Texas',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'TN',
    state_name: 'Tennessee',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'SD',
    state_name: 'South Dakota',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'SC',
    state_name: 'South Carolina',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'MT',
    state_name: 'Montana',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'MO',
    state_name: 'Missouri',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'MS',
    state_name: 'Mississippi',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'GA',
    state_name: 'Georgia',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'FL',
    state_name: 'Florida',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'DC',
    state_name: 'District Of Columbia',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'DE',
    state_name: 'Delaware',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'CT',
    state_name: 'Connecticut',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'CO',
    state_name: 'Colorado',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'CA',
    state_name: 'California',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'AR',
    state_name: 'Arkansas',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'AZ',
    state_name: 'Arizona',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'AK',
    state_name: 'Alaska',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'HI',
    state_name: 'Hawaii',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'ID',
    state_name: 'Idaho',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'IL',
    state_name: 'Illinois',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'MN',
    state_name: 'Minnesota',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'MI',
    state_name: 'Michigan',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'MA',
    state_name: 'Massachusetts',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'MD',
    state_name: 'Maryland',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'ME',
    state_name: 'Maine',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'LA',
    state_name: 'Louisiana',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'KY',
    state_name: 'Kentucky',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'KS',
    state_name: 'Kansas',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'IA',
    state_name: 'Iowa',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'IN',
    state_name: 'Indiana',
    display_status: '1',
  },
  {
    country_id: 'US',
    state_id: 'AL',
    state_name: 'Alabama',
    display_status: '1',
  },
]
  .sort((a, b) => {
    if (a.state_name > b.state_name) {
      return 1;
    } else if (a.state_name < b.state_name) {
      return -1;
    } else {
      return 0;
    }
  })
  .reduce((output, item) => {
    output[item.country_id]
      ? output[item.country_id].push({
        value: item.state_id,
        label: item.state_name,
      })
      : (output[item.country_id] = [
        { value: item.state_id, label: item.state_name },
      ]);
    return output;
  }, {});

stateList.IN = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Chandigarh',
  'Daman and Diu',
  'Dadra and Nagar Haveli',
  'Delhi',
  'Goa',
  'Gujarat',
  'Himachal Pradesh',
  'Haryana',
  'Jharkhand',
  'Jammu & Kashmir',
  'Karnataka',
  'Kerala',
  'Lakshadweep',
  'Maharashtra',
  'Meghalaya',
  'Manipur',
  'Madhya Pradesh',
  'Mizoram',
  'Nagaland',
  'Orissa',
  'Punjab',
  'Pondicherry',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Tripura',
  'Telangana',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
].map(item => ({ label: item, value: item }));
