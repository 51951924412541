import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h2: {
      fontSize: {
        base: 32,
        md: 44,
        lg: 64,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      color: '#433F3C',
    },
    stText: {
      fontFamily: config.langFont[lang],
    },
    cardTitle: {
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      fontSize: '18px',
      color: '#A81D28',
      lineHeight: '22.68px',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const ProgramSupportSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({
    lang: 'ar',
    h2: {
      fontFamily: "'Almarai', sans-serif",
      width: '100%',
      textAlign: { base: 'right', lg: 'left' },
    },
    stText: { fontFamily: "'Almarai', sans-serif", textAlign: 'right' },
    cardTitle: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
      display: 'flow',
      width: '100%',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default ProgramSupportSt;
