/* Built In Imports */
import { useRef, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import parser from 'html-react-parser';

/* Internal Imports */
/* Components */
import { validateEmail as validateEmailApi } from '@ieo/Components/Form/ieo/api/registrationFormApi';
import { useTranslation } from '@ieo/Components/Form/ieo/translation';

/*
New
Meditator
Active
Transfer
 */

const emailValidationStatus = {
  new: 'New',
  meditator: 'Meditator',
  active: 'Active',
  transfer: 'Transfer',
  differentVernacular: 'DifferentVernacular',
  reschedule: 'Reschedule',
  redo: 'RedoIEO',
  reRegister: 'Re-register',
};

export const EmailValidationMessage = ({
  status,
  feeAdjustment,
  registeredLanguage,
  regResponse,
  lang,
}) => {
  const { t } = useTranslation();

  const getFormattedDate = pDate => {
    const tDate = new Date(pDate);
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ][tDate.getMonth()];
    return `${tDate.getDate()}-${month}-${tDate.getFullYear()}`;
  };

  const getMessage = () => {
    if (status === emailValidationStatus.active) {
      return t('formContent.activeMessage');
    } else if (
      status === emailValidationStatus.meditator ||
      status === emailValidationStatus.redo
    ) {
      return t('formContent.meditatorMessage');
    } else if (status === emailValidationStatus.transfer) {
      return feeAdjustment === 'free'
        ? t('formContent.freeTransferMessage')
        : feeAdjustment === 'reduced'
        ? t('formContent.reducedFeeTransferMessage')
        : t('formContent.fullPriceTransferMessage');
    } else if (status === emailValidationStatus.differentVernacular) {
      return t('formContent.differentVernacularMessage');
    } else if (status === emailValidationStatus.reschedule) {
      return t('formContent.rescheduleMessage');
    } else if (status === emailValidationStatus.reRegister) {
      return t(
        new Date(regResponse?.endDate).getTime() > new Date().getTime()
          ? 'formContent.courseExpiry'
          : 'formContent.courseExpired'
      )
        .replace('#start#', getFormattedDate(regResponse?.startDate))
        .replace('#end#', getFormattedDate(regResponse?.endDate));
    } else {
      return t('');
    }
  };

  const replacedLanguage = (registeredLanguage || '')
    .replace('_', '-')
    .toLowerCase();
  const languageKey = replacedLanguage === 'zh-cn' ? 'zh' : replacedLanguage;
  const registeredLanguageText =
    registeredLanguage && t(`languages.${languageKey}`);

  return (
    <Box>
      <Box
        className="msgText"
        mb="20px"
        textAlign={lang === 'ar' ? 'right' : 'left'}
      >
        {parser(getMessage())}
      </Box>
      {status === emailValidationStatus.differentVernacular &&
        registeredLanguageText && (
          <>
            <p>
              {t('formContent.switchLanguage')} {registeredLanguageText}
            </p>
          </>
        )}
      <style global jsx>
        {`
          .course-text {
            margin: 15px 0;
          }
          .msgText {
            color: #5b5237;
            font-family: FedraSansStd-book;
          }
          .msgText h3 {
            color: #e86f34;
            font-size: 22px;
            line-height: 140%;
            font-family: FedraSansStd-medium;
          }
          .msgText b {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            padding-right: 10px;
            display: block;
            font-family: FedraSansStd-medium;
          }
        `}
      </style>
    </Box>
  );
};

export function useEmailValidationLogic(program) {
  const [status, setStatus] = useState({ showDialog: false });

  const prevEmail = useRef(null);
  const prevResult = useRef(null);

  /**
   * Called to validate email on step 1 form submission.
   */
  const validateEmail = async (email, firstName, lastName) => {
    // console.log('In validateEmail with', { email, firstName, lastName });

    // console.log('calling validate email API');
    const apiResult = await validateEmailApi(
      email,
      firstName,
      lastName,
      program.programId
    );

    prevResult.current = apiResult;

    // console.log('after email validate API', { apiResult });
    const result = prevResult.current;
    prevEmail.current = email;

    // This status indicates we can't continue. Show error message from API
    if (apiResult.status === false) {
      return { canContinue: false, result: apiResult, showNotice: true };
      // Check for currency mismatch and show error message
      // This shouldn't happen in regular scenarios hence if
      // they refresh that should fix the issue
    } else if (apiResult.curr && apiResult.curr !== program.curr) {
      return {
        canContinue: false,
        result: {
          status: false,
          message:
            'There was an error with processing your request. Please refresh the page and try again.',
        },
        showNotice: true,
      };
    }
    // console.log('apiResult', apiResult);

    // flow of disallowing users to switch language
    const isDifferentVernacularLanguage = false;
    //   apiResult.regType === emailValidationStatus.reschedule &&
    //   apiResult.language &&
    //   apiResult.language?.toUpperCase() !== program.lang?.toUpperCase();
    // if (isDifferentVernacularLanguage) {
    //   result.regType = emailValidationStatus.differentVernacular;
    // }

    const showNotice =
      result.regType !== emailValidationStatus.new ||
      isDifferentVernacularLanguage;
    const canContinue =
      result.regType !== emailValidationStatus.active &&
      !isDifferentVernacularLanguage;

    if (showNotice) {
      setStatus(status => ({
        ...status,
        validationType: result.regType,
        result: result,
      }));
    }

    return {
      showNotice,
      result: result,
      canContinue,
    };
  };

  return {
    validateEmail,
    canContinue: status.canContinue,
  };
}
