import React, { useContext, useState } from 'react';

export const ProgramLanguageSelectionContext = React.createContext(undefined);

export const ProgramLanguageSelectionProvider = ({ children }) => {
  const [programLanguage, setProgramLanguage] = useState(null);

  // console.log('In Provider', { programLanguage });

  return (
    <ProgramLanguageSelectionContext.Provider
      value={{ programLanguage, setProgramLanguage }}
    >
      {children}
    </ProgramLanguageSelectionContext.Provider>
  );
};

export const useProgramLanguageSelection = () => {
  const contextValue = useContext(ProgramLanguageSelectionContext);

  if (!contextValue)
    throw new Error(
      'useProgramLanguageSelection needs to be used with LanguageContext'
    );

  return contextValue;
};
