import config from '@config';
import merge from 'lodash/merge';
const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    heading: {
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontSize: { base: '20px', md: '26px' },
      fontWeight: isIndian ? 600 : 'normal',
    },
    subHeading: {
      fontFamily: config.langFont[lang],
      fontSize: { base: '16px', md: '20px' },
    },
    cardHeading: {
      fontSize: {
        base: '20px',
        md: '24px',
        lg: '26px',
      },
      lineHeight: {
        base: '20px',
        md: '20px',
        lg: '20px',
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 600 : 'normal',
      color: '#F37021',
      mb: { base: '16px', md: '20px', lg: '25px' },
    },
    cardDesc: {
      fontFamily: config.langFont[lang],
      fontSize: { base: '16px', md: '18px' },
      fontWeight: isIndian ? 400 : 'normal',
      letterSpacing: { base: '-0.08px', md: '-0.09px' },
      fontStyle: 'normal',
      lineHeight: { base: '24px', md: '27px' },
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const ProgramRequirementSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({
    lang: 'ar',
    heading: {
      fontFamily: "'Almarai', sans-serif",
    },
    subHeading: {
      fontFamily: "'Almarai', sans-serif",
    },
    cardDesc: { fontFamily: "'Almarai', sans-serif", textAlign: 'right' },
    cardHeading: { fontFamily: "'Almarai', sans-serif", textAlign: 'right' },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default ProgramRequirementSt;
