import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    stFaText: {
      fontSize: { base: '20px', md: '32px' },
      color: '#E86F34',
      lineHeight: isIndian
        ? { base: '32px', md: '45.2px' }
        : { base: '22px', md: '35.2px' },
      fontWeight: { base: '450', md: '400' },
      fontFamily: config.langFont[lang],
      textTransform: 'uppercase',
    },

    stQText: {
      fontSize: { base: '34px', md: '64px' },
      color: '#433F3C',
      lineHeight: isIndian
        ? { base: '37.98px', md: '67.08px' }
        : { base: '32.98px', md: '62.08px' },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 600 : 'normal',
    },
  };
  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const FaqContentsSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({
    lang: 'ar',
    stFaText: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },

    stQText: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default FaqContentsSt;
