/* Built In Imports */
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

/* External Imports */
import { useQuery } from '@tanstack/react-query';

/* Internal Imports */
/* Components */
import { getCurrentRegion } from '@Services/commonService';

/* Services */

export const RegionContext = React.createContext(undefined);

export const useRegion = () => {
  const contextValue = useContext(RegionContext);

  if (!contextValue)
    throw new Error('useRegion needs to be used with RegionContext');

  return contextValue;
};

export const useCountry = () => {
  const router = useRouter();
  const countryFromQuery = router.query['geo-country'];
  const region = useRegion();

  return useQuery({
    queryKey: ['geo-facade', countryFromQuery],
    queryFn: async () => {
      if (countryFromQuery) return countryFromQuery;

      try {
        const res = await getCurrentRegion();
        if (!res.country)
          throw new Error('Did not get country from getCurrentRegion');

        return res.country;
      } catch (error) {
        console.error(error);
        return region.toUpperCase();
      }
    },
    staleTime: Infinity,
  });
};

export const useIsIndia = () => {
  const region = useRegion();

  const queryResult = useCountry();

  const country = queryResult.isSuccess
    ? queryResult.data
    : region.toUpperCase();

  return country === 'IN';
};
