/* Built In Imports */

/* External Imports */

/* Internal Imports */
/* Components */
import StructuredTextContent from '@UI/StructuredTextContent';
import { useSectionContent } from '@ieo/Components/Form/ieo/context/sectionContentContext';
import { HookFormScrollableTermsField } from '@ieo/Components/Form/ieo/formFields';

/* Services */

export const DisclaimerWaiverField = () => {
  const sectionContent = useSectionContent();

  const headingContent = sectionContent.find(
    item => item.fieldId === 'ieo-enroll-disclaimer-section'
  );
  const subHeadingContent = sectionContent.find(
    item => item.fieldId === 'ieo-enroll-disclaimer-decs'
  );

  const termsContent = sectionContent.find(
    item => item.fieldId === 'ieo-enroll-disclaimer-drop-section'
  );

  return (
    <HookFormScrollableTermsField
      name={'disclaimerWaiver'}
      heading={StructuredTextContent(headingContent?.body?.value)}
      subHeading={StructuredTextContent(subHeadingContent?.body?.value)}
      content={StructuredTextContent(termsContent?.body?.value)}
    />
  );
};

export const ReleaseWaiverField = () => {
  const sectionContent = useSectionContent();

  const headingContent = sectionContent.find(
    item => item.fieldId === 'ieo-enroll-waiver-section'
  );
  const subHeadingContent = sectionContent.find(
    item => item.fieldId === 'ieo-enroll-disclaimer-decs'
  );

  const termsContent = sectionContent.find(
    item => item.fieldId === 'ieo-enroll-release-drop-section'
  );

  return (
    <HookFormScrollableTermsField
      name={'liabilityWaiver'}
      heading={StructuredTextContent(headingContent?.body?.value)}
      subHeading={StructuredTextContent(subHeadingContent?.body?.value)}
      content={StructuredTextContent(termsContent?.body?.value)}
      textAlign="center"
    />
  );
};
