import config from '@config';
import { getApiUrl } from '@ieo/Components/Form/ieo/api/apiUtils';
import Axios from 'axios';

/*
Response for free transfer registration

{
    requestId: '38990',
    programType: 'IEO',
    programCode: '99',
    ssoId: '0f7b73d4a5ac4d9f902274bffeda13b1',
    firstName: 'ieoiso5',
    lastName: 'test',
    email: 'ieoiso5@yopmail.com',
    languageCode: 'EN',
    mobilePhone: '8608666909',
    addressLine1: '951 Isha Lane',
    addressLine2: '',
    city: 'McMinnville',
    state: 'Tennessee',
    postCode: '37110',
    country: 'VN',
    amount: 0,
    currency: 'INR',
    token: 'e6c7982bf822fb94e25617f3bafcc285',
    callbackUrl:
      'https://qa-prs.innerengineering.com/ieo/newadmin/forms/ieo-payment-status.php?pgmId=20339&pgmType=99&requestId=38990',
  }
 */

export function registerForProgram(pgmTypeId, pgmId, data) {
  // console.log('data1', data);
  return Axios.post(
    getApiUrl() + `/program/register/${pgmTypeId}/${pgmId}`,
    data
  ).then(res => {
    // console.log('Register for gifting flow program response', res);
    return res.data;
  });
}

export function registerForNewApiProgram(data) {
  // console.log('data1', data);
  return Axios.post(config.IEO_REGISTER_URL, data).then(res => {
    // console.log('Register for gifting flow program response', res);
    return res.data;
  });
}

export const registerForGiftingFlow = async (pgmTypeId, pgmId, data) => {
  // console.log('data', data);
  let res = await fetch(getApiUrl() + `/gift/purchase/${pgmTypeId}/${pgmId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  res = await res.json();
  return res;
};

export const verifyCoupon = async data => {
  // let res = await fetch(getApiUrl() + `/coupon/apply`, {
  //   method: 'POST',
  //   headers: {
  //     // Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify(data),
  // });
  // res = await res.json();
  // // console.log('verifyCoupon', data);

  const res = await Axios.get(
    `${config.IEO_COUPON_APPLY}?cCode=${data.cCode}&pgmId=${data.pgmId}&pgmCtry=${data.pgmCtry}&src=${data.src}`
  );
  return res.data;
};

export const getPreSignedUrlForUpload = async data => {
  let res = await fetch(config.IEO_PRE_SIGNED_UPLOAD_URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  //console.log('getPreSignedUrlForUpload resp', res);
  res = await res.json();
  return res;
  // // console.log('data1', data);
  // return Axios.post(config.IEO_PRE_SIGNED_UPLOAD_URL, data).then(res => {
  //   // console.log('Register for gifting flow program response', res);
  //   return res.data;
  // });
};

export const uploadFileToS3 = async (url, data) => {
  console.log('uploadFileToS3 url', url, data);
  let res = await fetch(url, {
    method: 'POST',
    body: data,
  });
  // console.log('uploadFileToS3 resp', res);
  return res;
  // return Axios.post(url, data).then(res => {
  //   return res;
  // });
};

/* Sample response
https://qa-prs.innerengineering.com/ieo/newadmin/api/ieo/userCheck?pgmId=99&email=alex.m@ishausa.org&fname=alex&lname=makkaveyev
{
    "startStepId": 1,
    "pgmFee": "325",
    "curr": "USD",
    "IEOStatus": "Not Started",
    "regType": "Transfer",
    "is_vernacular ": false,
    "language": "EN",
    "address": {
        "address1": "Isha Institute of Inner-sciences, 951 Isha Lane",
        "address2": "",
        "city": "McMinnville",
        "zip": "37110",
        "state": "TN",
        "country": "US"
    }
}
 */

export function validateEmail(
  email,
  firstName,
  lastName,
  programId,
  isoCode,
  phone
) {
  return Axios.get(config.IEO_VALIDATE_EMAIL_URL, {
    params: {
      email,
      fname: firstName,
      lname: lastName,
      pgmId: programId,
      isoCode,
      phone,
    },
  }).then(res => {
    return res.data;
  });
}
