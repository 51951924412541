export const RegionList = {
    "IN": "India",
    "US": "Americas",
    "RU": "Russia",
    "MX": "Americas",
    "UA": "Russia",
    "CO": "Americas",
    "BR": "Americas",
    "ES": "UK/Europe",
    "FR": "UK/Europe",
    "AR": "Americas",
    "DE": "UK/Europe",
    "NP": "India",
    "CA": "Americas",
    "KZ": "Russia",
    "BD": "India",
    "GB": "UK/Europe",
    "PE": "Americas",
    "IT": "UK/Europe",
    "CL": "Americas",
    "AU": "APAC",
    "AE": "ME",
    "EC": "Americas",
    "MY": "APAC",
    "BY": "UK/Europe",
    "CH": "UK/Europe",
    "ZA": "Africa",
    "SG": "APAC",
    "DO": "Americas",
    "VE": "Americas",
    "UY": "Americas",
    "CR": "Americas",
    "NL": "UK/Europe",
    "BE": "UK/Europe",
    "PT": "UK/Europe",
    "PH": "APAC",
    "BO": "Americas",
    "CI": "Africa",
    "KG": "Russia",
    "PK": "India",
    "SA": "ME",
    "AT": "UK/Europe",
    "MU": "Africa",
    "MA": "Africa",
    "SN": "Africa",
    "CM": "Africa",
    "IL": "ME",
    "SV": "Americas",
    "GT": "Americas",
    "SE": "UK/Europe",
    "DZ": "Africa",
    "PA": "Americas",
    "MD": "UK/Europe",
    "TW": "APAC",
    "HK": "APAC",
    "RE": "Africa",
    "PY": "Americas",
    "IQ": "ME",
    "BJ": "Africa",
    "UZ": "ME",
    "TG": "Africa",
    "RO": "UK/Europe",
    "BF": "Africa",
    "AM": "UK/Europe",
    "JP": "APAC",
    "ML": "Africa",
    "CD": "Africa",
    "HN": "Americas",
    "GP": "Americas",
    "GA": "UK/Europe",
    "AZ": "UK/Europe",
    "TN": "Africa",
    "LV": "UK/Europe",
    "HT": "Americas",
    "MQ": "Americas",
    "LT": "UK/Europe",
    "EG": "Africa",
    "PR": "Americas",
    "NI": "Americas",
    "GE": "UK/Europe",
    "TR": "ME",
    "SY": "ME",
    "PL": "UK/Europe",
    "GN": "Africa",
    "AO": "Africa",
    "CG": "Africa",
    "PF": "APAC",
    "MG": "Africa",
    "LB": "ME",
    "JO": "ME",
    "NC": "APAC",
    "MZ": "Africa",
    "CU": "ME",
    "BG": "UK/Europe",
    "TH": "APAC",
    "LU": "UK/Europe",
    "MO": "APAC",
    "NE": "UK/Europe",
    "GR": "UK/Europe",
    "GF": "Americas",
    "KW": "ME",
    "OM": "ME",
    "CZ": "UK/Europe",
    "ID": "APAC",
    "NZ": "APAC",
    "LK": "India",
    "IE": "UK/Europe",
    "KE": "Africa",
    "TT": "Americas",
    "NG": "Africa",
    "QA": "ME",
    "NO": "UK/Europe",
    "DK": "UK/Europe",
    "GH": "Africa",
    "HR": "UK/Europe",
    "FI": "UK/Europe",
    "ET": "Africa",
    "RS": "UK/Europe",
    "SI": "UK/Europe",
    "JM": "Americas",
    "KR": "APAC",
    "VN": "APAC",
    "HU": "UK/Europe",
    "TZ": "Africa",
    "BH": "ME",
    "GY": "Americas",
    "BT": "APAC",
    "UG": "Africa",
    "ZM": "Africa",
    "SK": "UK/Europe",
    "KH": "APAC",
    "CY": "ME",
    "EE": "UK/Europe",
    "MM": "APAC",
    "MK": "UK/Europe",
    "NA": "Africa",
    "AL": "UK/Europe",
    "FJ": "APAC",
    "RW": "Africa",
    "SR": "Americas",
    "MT": "UK/Europe",
    "BW": "Africa",
    "BB": "Americas",
    "BA": "UK/Europe",
    "ZW": "Africa",
    "CN": "China",
    "Rest of them": "Americas"
}