import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';
const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h2: {
      fontSize: {
        base: 28,
        md: 44,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      wordBreak: 'break-word',
    },

    title: {
      fontSize: {
        base: '20px',
        md: '26px',
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
    subTitle: {
      fontSize: 16,
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
    },
    p: {
      fontSize: {
        base: 16,
        md: 18,
      },
      fontFamily: isIndian ? config.langFont[lang] : "'FedraSansStd-book-i'",
      fontWeight: isIndian ? 500 : 'normal',
    },
    countNumber: {
      fontSize: {
        base: 16,
        md: 22,
        lg: 30,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const ProgramTestimonial = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn', 
    h2: {     
      wordBreak: 'break-all',
    }
  }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({
    lang: 'ar',
    h2: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    title: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    subTitle: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    p: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default ProgramTestimonial;
