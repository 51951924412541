import config from '@config';
import { useQuery } from '@tanstack/react-query';

const formatSsoDataForForm = rawData => {
  const data = rawData?.[0];

  if (!data) return {};

  const formatted = {
    fname: data.firstName,
    lname: data.lastName,
    email: data.email,
    pPhone: data.phone?.number,
    pCountry: data.phone?.countryCode,
    country: data.country,
    sPhone: data.whatsapp?.number,
    sCountry: data.whatsapp?.countryCode,
    zip: data.pincode,
    state: data.state,
    city: data.city,
    address1: data.addressLine1,
  };

  return Object.fromEntries(Object.entries(formatted).filter(item => item[1]));
};

export const getSsoData = async () => {
  try {
    // TODO: Move below URL to config
    const res = await fetch(`${config.FACADE_BASE_PATH}/get-sso-user-profile`, {
      credentials: 'include',
    });

    if (!res.ok) {
      throw new Error('Could not fetch SSO data');
    }

    const data = await res.json();

    // console.log('Data from SSO', { data: data });
    // TODO: check for success

    return formatSsoDataForForm(data);
  } catch (error) {
    return {};
    // return formatSsoDataForForm([
    //   {
    //     email: 'alex.m+1@ishausa.org',
    //     firstName: 'Alex',
    //     lastName: 'Makkaveyev',
    //     profilePic: null,
    //     phone: {
    //       countryCode: null,
    //       number: '9085477954',
    //     },
    //     whatsapp: {
    //       countryCode: null,
    //       number: null,
    //     },
    //     gender: null,
    //     addressLine1: '2270 Cahaba Ct',
    //     addressLine2: '',
    //     townVillageDistrict: null,
    //     city: 'Cumming',
    //     state: 'GA',
    //     country: 'US',
    //     pincode: '30041',
    //   },
    // ]);
  }
};

export const useSsoData = ({ isLoginComplete, isLoggedIn }) => {
  const query = useQuery({
    queryKey: ['sso-user-data'],
    queryFn: async () => {
      if (!isLoggedIn) {
        return Promise.resolve({});
      }
      return getSsoData();
    },
    staleTime: Infinity,
    enabled: isLoginComplete,
  });

  return query;
};
