import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;

  const styleObj = {
    heading: {
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 600 : 'normal',
    },
    stText: {
      fontFamily: lang ? config.langFont[lang] : fontFamily,
      fontWeight: 500,
    },
    cardText: {
      fontFamily: lang ? config.langFont[lang] : fontFamily,
    },
    h3: {
      color: '#12166F',
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontSize: { base: '20px', md: '30px' },
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: { base: '19.4px', md: '29.1px' },
    },
    popUpTitle: {
      color: '#A81D28',
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontSize: { base: '18px', md: '18px' },
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: { base: '23.4px', md: '23.4px' },
    },

    popUpDesc: {
      color: '#423E3B',
      fontFamily: lang ? config.langFont[lang] : fontFamily,
      fontSize: { base: '16px', md: '18px' },
      fontStyle: 'normal',
      fontWeight: { base: '500', md: '400' },
      lineHeight: { base: '22.4px', md: '27px' },
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const ResearchShowsSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({
    lang: 'ar',
    heading: {
      fontFamily: "'Almarai', sans-serif",
    },
    stText: {
      fontFamily: "'Almarai', sans-serif",
    },
    h3: {
      fontFamily: "'Almarai', sans-serif",
    },
    popUpDesc: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default ResearchShowsSt;
