/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box, Icon, Text } from '@chakra-ui/react';
import parser from 'html-react-parser';
import { useFormContext } from 'react-hook-form';

/* Internal Imports */
/* Components */
import { CountryWithoutChina } from '@constants/countryCodes';
import { useIsIndia } from '@ieo/Components/Form/ieo/context/regionContext';
import { IeoFormFieldGrid } from '@ieo/Components/Form/ieo/elements/formComponents';
import { useTranslation } from '@ieo/Components/Form/ieo/translation';
import {
  FormErrorMessage,
  SubmitButton,
  blankSpaceValidation,
  emailCharactersValidation,
  englishCharactersValidation,
} from './formElements';
import {
  HookFormCheckboxField,
  HookFormSelectField,
  HookFormTextField,
} from './formFields';
/* Styles */

const countryOptions = CountryWithoutChina.map(item => (
  <option value={item.value} key={item.value}>
    {item.label}
  </option>
));

export function Step1FormFieldsCorporates({
  disableCountryPicker,
  disableFirstLastEmailFields,
  hasPrefilledFirstLastName,
  lang,
  pageData,
  setValue,
  successMsg,
  ...props
}) {
  const { t } = useTranslation();
  const isIndia = useIsIndia();

  const transProviderObj =
    (pageData._allSharedJsonLocales?.length &&
      pageData._allSharedJsonLocales[0]?.value.json) ||
    pageData.sharedJson?.json;

  const getValuePrivacyPolicy = parser(t('formFields.privacyPolicy'));

  return (
    <>
      {props.resDataCorp?.response ? (
        <Box
          padding={{ base: '50px 15px', md: '30% 30px' }}
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyItems="center"
          textAlign="center"
          rowGap="20px"
        >
          <Icon viewBox="0 0 40 40" w="40" h="10">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.9997 39.1666C30.5851 39.1666 39.1663 30.5854 39.1663 19.9999C39.1663 9.41446 30.5851 0.833252 19.9997 0.833252C9.41422 0.833252 0.833008 9.41446 0.833008 19.9999C0.833008 30.5854 9.41422 39.1666 19.9997 39.1666ZM17.2673 28.6027L28.4471 14.9385L25.4803 12.5111L16.9827 22.897L13.2916 19.2059L10.5811 21.9165L17.2673 28.6027Z"
              fill="#00A171"
            />
          </Icon>
          <Text fontSize={{ base: '20px', md: '24px' }}>
            {successMsg.heading}
          </Text>
          <Text
            fontSize={{ base: '18px', md: '20px' }}
            fontFamily="'FedraSansStd-book'"
          >
            {successMsg.subText}
          </Text>
          <Text
            fontSize={{ base: '18px', md: '20px' }}
            fontFamily="'FedraSansStd-book'"
          >
            {successMsg.subHeading}
          </Text>
        </Box>
      ) : (
        <IeoFormFieldGrid gridTemplateColumns={'1fr'}>
          {lang == 'ar' ? (
            <>
              <HookFormTextField
                dir="rtl"
                name={'lname'}
                label={t('formFields.lastName')}
                disabled={disableFirstLastEmailFields}
                required
                lang={lang}
                rules={{
                  required: t('validationMessages.lastName.required'),
                  validate: {
                    blankSpace: value =>
                      blankSpaceValidation.value.test(value) ||
                      t(blankSpaceValidation.message),
                    englishCharacters: value =>
                      isIndia
                        ? englishCharactersValidation.value.test(value) ||
                          t(englishCharactersValidation.message)
                        : null,
                  },
                  maxLength: 100,
                  minLength: 1,
                }}
                testId={'lname-input'}
              />
              <HookFormTextField
                dir="rtl"
                name={'fname'}
                label={t('formFields.firstName')}
                disabled={disableFirstLastEmailFields}
                required
                testId={'fname-input'}
                lang={lang}
                rules={{
                  required: t('validationMessages.firstName.required'),
                  validate: {
                    blankSpace: value =>
                      blankSpaceValidation.value.test(value) ||
                      t(blankSpaceValidation.message),
                    englishCharacters: value =>
                      isIndia
                        ? englishCharactersValidation.value.test(value) ||
                          t(englishCharactersValidation.message)
                        : null,
                  },
                  maxLength: 100,
                  minLength: isIndia ? 3 : 1,
                }}
              />
            </>
          ) : (
            <>
              <IeoFormFieldGrid
                gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
              >
                <Box fontFamily="'FedraSansStd-book'" as="label">
                  {t('formFields.firstName')}{' '}
                  <Box as="span" color="#B10E1C">
                    *
                  </Box>
                  <HookFormTextField
                    name={'fname'}
                    label={t('formFields.firstName')}
                    disabled={disableFirstLastEmailFields}
                    required
                    disablePlaceholder={true}
                    testId={'fname-input'}
                    mt="8px"
                    lang={lang}
                    borderRadius={'4px'}
                    borderColor={'#D1D1D1'}
                    rules={{
                      required: t('validationMessages.firstName.required'),
                      validate: {
                        blankSpace: value =>
                          blankSpaceValidation.value.test(value) ||
                          t(blankSpaceValidation.message),
                        englishCharacters: value =>
                          isIndia
                            ? englishCharactersValidation.value.test(value) ||
                              t(englishCharactersValidation.message)
                            : null,
                      },
                      maxLength: 100,
                      minLength: isIndia ? 3 : 1,
                    }}
                  />
                </Box>

                <Box fontFamily="'FedraSansStd-book'" as="label">
                  {t('formFields.lastName')}{' '}
                  <Box as="span" color="#B10E1C">
                    *
                  </Box>
                  <HookFormTextField
                    name={'lname'}
                    label={t('formFields.lastName')}
                    disabled={disableFirstLastEmailFields}
                    required
                    disablePlaceholder={true}
                    lang={lang}
                    borderRadius={'4px'}
                    borderColor={'#D1D1D1'}
                    mt="8px"
                    rules={{
                      required: t('validationMessages.lastName.required'),
                      validate: {
                        blankSpace: value =>
                          blankSpaceValidation.value.test(value) ||
                          t(blankSpaceValidation.message),
                        englishCharacters: value =>
                          isIndia
                            ? englishCharactersValidation.value.test(value) ||
                              t(englishCharactersValidation.message)
                            : null,
                      },
                      maxLength: 100,
                      minLength: 1,
                    }}
                    testId={'lname-input'}
                  />
                </Box>
              </IeoFormFieldGrid>

              <Box fontFamily="'FedraSansStd-book'" as="label">
                {t('formFields.workEmail')}{' '}
                <Box as="span" color="#B10E1C">
                  *
                </Box>
                <HookFormTextField
                  dir={lang == 'ar' ? 'rtl' : 'ltr'}
                  name={'workEmail'}
                  mt="8px"
                  label={t('formFields.workEmail')}
                  type={'email'}
                  borderRadius={'4px'}
                  borderColor={'#D1D1D1'}
                  required
                  disablePlaceholder={true}
                  rules={{
                    pattern: {
                      value: emailCharactersValidation.value,
                      message: t(emailCharactersValidation.message),
                    },
                    maxLength: 120,
                  }}
                  testId={'email-input'}
                />
              </Box>

              <Box fontFamily="'FedraSansStd-book'" as="label">
                {t('formFields.jobTitle')}{' '}
                <Box as="span" color="#B10E1C">
                  *
                </Box>
                <HookFormTextField
                  name={'jobTitle'}
                  label={t('formFields.jobTitle')}
                  required
                  disablePlaceholder={true}
                  lang={lang}
                  borderRadius={'4px'}
                  borderColor={'#D1D1D1'}
                  mt="8px"
                  rules={{
                    required: t('validationMessages.jobTitle.required'),
                    validate: {
                      blankSpace: value =>
                        blankSpaceValidation.value.test(value) ||
                        t(blankSpaceValidation.message),
                      englishCharacters: value =>
                        isIndia
                          ? englishCharactersValidation.value.test(value) ||
                            t(englishCharactersValidation.message)
                          : null,
                    },
                    maxLength: 100,
                    minLength: 1,
                  }}
                  testId={'jobTitle-input'}
                />
              </Box>

              <Box fontFamily="'FedraSansStd-book'" as="label">
                {t('formFields.companyName')}{' '}
                <Box as="span" color="#B10E1C">
                  *
                </Box>
                <HookFormTextField
                  name={'companyName'}
                  label={t('formFields.companyName')}
                  required
                  disablePlaceholder={true}
                  lang={lang}
                  borderRadius={'4px'}
                  borderColor={'#D1D1D1'}
                  mt="8px"
                  rules={{
                    required: t('validationMessages.companyName.required'),
                    validate: {
                      blankSpace: value =>
                        blankSpaceValidation.value.test(value) ||
                        t(blankSpaceValidation.message),
                      englishCharacters: value =>
                        isIndia
                          ? englishCharactersValidation.value.test(value) ||
                            t(englishCharactersValidation.message)
                          : null,
                    },
                    maxLength: 100,
                    minLength: 1,
                  }}
                  testId={'companyName-input'}
                />
              </Box>

              <Box fontFamily="'FedraSansStd-book'" as="label">
                {t('formFields.country')}{' '}
                <Box as="span" color="#B10E1C">
                  *
                </Box>
                <HookFormSelectField
                  name={'country'}
                  borderRadius={'4px'}
                  borderColor={'#D1D1D1'}
                  mt="8px"
                  label={t('formFields.country')}
                  required
                  disablePlaceholder={true}
                  //disabled={disableCountryPicker}
                  options={countryOptions}
                  testId={'country-input'}
                />
              </Box>

              <Box
                fontFamily="'FedraSansStd-book'"
                mt="10px"
                fontSize="14px"
                className="alinkPrivacyPolicy"
              >
                <HookFormCheckboxField
                  name={'privacyPolicy'}
                  label={getValuePrivacyPolicy}
                  required
                  lang={lang}
                  alignItems="baseline"
                />
              </Box>
            </>
          )}
          <style jsx global>
            {`
              .alinkPrivacyPolicy a {
                color: #3652b8;
              }
            `}
          </style>
        </IeoFormFieldGrid>
      )}
    </>
  );
}

export function Step1FormCorporates({
  onSubmit,
  errorMessage,
  isSubmitting,
  disableCountryPicker,
  pageData,
  lang,
  disableFirstLastEmailFields,
  hasPrefilledFirstLastName,
  isTransfer,
  program,
  region,
  isReducedFee,
  updatedProgramFee,
  originalProgramFee,
  setValue,
  pageName,
  resDataCorp,
  successMsg,
}) {
  const { t } = useTranslation();
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const { trigger } = useFormContext();
  // const getLanguageName = code => t(`languages.${code.toLowerCase()}`);

  return (
    <Box
      px={{ base: '14px', md: '40px' }}
      mb={{ base: '30px', md: '50px' }}
      pt={{ base: '20px', md: '30px' }}
    >
      <form onSubmit={onSubmit}>
        <Step1FormFieldsCorporates
          disableCountryPicker={disableCountryPicker}
          disableFirstLastEmailFields={disableFirstLastEmailFields}
          hasPrefilledFirstLastName={hasPrefilledFirstLastName}
          lang={lang}
          pageData={pageData}
          setValue={setValue}
          resDataCorp={resDataCorp}
          successMsg={successMsg}
        />

        <Box
          mt={{ base: 6, md: 8 }}
          textAlign={{
            base: pageName === 'corporate' ? 'center' : 'left',
            md: 'left',
          }}
        >
          {errorMessage && clickedSubmit && !isSubmitting && (
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          )}
          {resDataCorp?.response == null && (
            <SubmitButton
              label={t(
                isTransfer ? 'formFields.continue' : 'formFields.submit'
              )}
              isSubmitting={isSubmitting}
              testId={'step1-submit'}
              onClick={() => {
                // Show validation messages for all form fields
                trigger();
                setClickedSubmit(true);
              }}
            />
          )}
        </Box>
      </form>

      <style jsx global>
        {`
          .pAtext p a {
            color: #3652b8 !important;
            text-decoration: underline;
          }
          .pAtext p a:hover {
            text-decoration: none;
          }
        `}
      </style>
    </Box>
  );
}
