import React, { useContext } from 'react';

export const LanguageContext = React.createContext(undefined);

export const useLanguage = () => {
  const contextValue = useContext(LanguageContext);

  if (!contextValue)
    throw new Error('useLanguage needs to be used with LanguageContext');

  return contextValue.lang;
};
