/* Built In Imports */
import React, { useContext } from 'react';

/* External Imports */
import get from 'lodash/get';

/* Internal Imports */
/* Components */
/* Services */

export const TranslationContext = React.createContext(null);

export const useTranslation = () => {
  const translationData = useContext(TranslationContext);

  if (!translationData)
    throw new Error(
      'useTranslation needs data provided via TranslationContext'
    );

  return {
    t: value => get(translationData, value) ?? value,
  };
};

export const Trans = ({ i18nKey, values }) => {
  const { t } = useTranslation();
  const translation = t(i18nKey);
  if (!values) return translation;

  return Object.entries(values).reduce((acc, item) => {
    return translation.replace(`{${item[0]}}`, item[1]);
  });
};

export const getLanguageUrlParam = language => language;

export function useLanguageUrlParam() {
  const {
    i18n: { language },
  } = useTranslation();

  return getLanguageUrlParam(language);
}
