import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const fontFamilyBook = 'FedraSansStd-book';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h2: {
      fontSize: {
        base: 32,
        md: 44,
        lg: 64,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
    p: {
      fontSize: {
        base: 16,
        md: 26,
      },
      fontFamily: config.langFont[lang],
      fontWeight: 'normal',
    },
    p1: {
      fontSize: {
        base: 16,
        md: 22,
      },
      fontFamily: config.langFont[lang],
      fontWeight: 'normal',
    },
    feeDiscount1: {
      fontSize: {
        base: 16,
        md: 22,
      },
      fontFamily: config.langFont[lang],
      fontWeight: 600,
      margin: '0',
    },
    feeDiscount2: {
      fontSize: 26,
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      margin: { base: '10px 0 0 0', md: '30px 0 0px 0' },
    },
    retakeHeading: {
      fontSize: { base: 20, md: 24 },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      margin: 0,
      color: '#433F3C',
    },
    retakeTextP: {
      fontFamily: isIndian ? config.langFont[lang] : fontFamilyBook,
      fontWeight: isIndian ? 500 : 500,
      fontSize: {
        base: 16,
        md: 18,
      },
      color: '#6D6358',
    },
    feeDiscount3: {
      fontSize: {
        base: 32,
        md: 50,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: 'normal',
      margin: '0',
    },
    couponFeeDiscount3: {
      fontSize: {
        base: 18,
        md: 22,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: 'normal',
      margin: '0',
    },
    postCouponPrice: {
      fontSize: {
        base: 28,
        md: 36,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: 'normal',
      margin: '0',
    },
    couponFee: {
      fontSize: {
        base: 28,
        md: 36,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: 'normal',
      margin: '0',
    },
    couponFeeDiscount3: {
      fontSize: {
        base: 18,
        md: 22,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: 'normal',
      margin: '0',
    },
    alreadyCompleted: {
      fontFamily: isIndian ? config.langFont[lang] : fontFamilyBook,
      fontWeight: isIndian ? 500 : 'normal',
      fontSize: {
        base: 16,
        md: 16,
      },
      color: '#6D6358',
    },
    regionalLanguages: {
      fontSize: {
        base: 22,
        md: 32,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      margin: { base: '20px 0 0 0', md: '0' },
    },
    selectLang: {
      fontSize: {
        base: 16,
        md: 18,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
    selectLangDrowDown: {
      fontSize: {
        base: 16,
        md: 20,
      },
      fontFamily: config.langFont[lang],
      fontWeight: 'normal',
    },
    tableHead: {
      fontSize: {
        base: 18,
        md: 22,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      letterSpacing: '0px',
    },
    bodyTd: {
      fontSize: {
        base: '16px',
        md: 22,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const ProgramFeeSt = {
  en: commonLangStyle({
    lang: 'en',
  }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({
    lang: 'hi',
    feeDiscount1: {
      fontSize: {
        base: 16,
        md: 22,
      },
      fontWeight: 600,
      'margin-top': '20px',
    },
    feeDiscount2: {
      fontSize: 26,
      fontWeight: 'normal',
      margin: { base: '10px 0 0 0', md: '30px 0 0px 0' },
    },
  }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: {
    h2: {
      ...commonLangStyle.h2,
      fontFamily: "'Noto Sans TC', sans-serif",
    },
    p: {
      ...commonLangStyle.p,
      fontFamily: "'Noto Sans TC', sans-serif",
    },
    p1: {
      ...commonLangStyle.p1,
      fontFamily: "'Noto Sans TC', sans-serif",
    },
    feeDiscount1: {
      ...commonLangStyle.feeDiscount1,
      fontFamily: "'Noto Sans TC', sans-serif",
      margin: '0',
    },
    feeDiscount2: {
      ...commonLangStyle.feeDiscount2,
      fontFamily: "'Noto Sans TC', sans-serif",
      margin: { base: '10px 0 0 0', md: '30px 0 0px 0' },
    },
    feeDiscount3: {
      ...commonLangStyle.feeDiscount3,
      fontFamily: "'Noto Sans TC', sans-serif",
      margin: '0',
    },
    regionalLanguages: {
      ...commonLangStyle.regionalLanguages,
      fontFamily: "'Noto Sans TC', sans-serif",
      margin: { base: '20px 0 0 0', md: '0' },
    },
    tableHead: {
      ...commonLangStyle.tableHead,
      fontFamily: "'Noto Sans TC', sans-serif",
      fontWeight: 'normal',
    },
    bodyTd: {
      ...commonLangStyle.bodyTd,
      fontFamily: "'Noto Sans TC', sans-serif",
    },
  },
  ar: commonLangStyle({
    lang: 'ar',
    h2: {
      ...commonLangStyle.h2,
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    p: {
      ...commonLangStyle.p,
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    p1: {
      ...commonLangStyle.p1,
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    feeDiscount1: {
      fontFamily: "'Almarai', sans-serif",
      fontWeight: 400,
      textAlign: 'right',
    },
    selectLangDrowDown: {
      fontFamily: "'FedraSansStd-book', sans-serif",
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default ProgramFeeSt;
