export const programTypes = {
  IET: 0,
  IER: 1,
  IEC: 10,
  IECO: 1008, // Not a real ID, used for internal purposes for now
  IEI: 1009, // Also not a real ID
  IECMega: 18,
  IEO: 99,
  AllIE: -2,
  Hatha_Yogasanas: 3,
  Hatha_Angamardana: 4,
  Hatha_SuryaKriya: 5,
  Hatha_SuryaShakti: 6,
  Hatha_ChildrensProgram: 7,
  AllHatha: -3,
  All: -1,
};
