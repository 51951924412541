/* Built In Imports */
/* External Imports */
import axios from 'axios';

/* Internal Imports */
/* Components */
import config from '@config';
import { tryGetPreviewData } from 'utils';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { filterBuildUrls, getLatestBuild } from 'services/commonService';
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';
const isCache = false;
const API_PREFIX = '/content/fetchiso';

export const fetchDetails = async (region, lang) => {
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        slug: `inner-engineering-online`,
        region,
        lang,
      },
    }
  );
  return pageData;
};

export const getLandingPaths = async (level, url, notMatchText, section) => {
  const data = config.BUILD_URLS?.OTHERS?.enabled
    ? config.BUILD_URLS.OTHERS
    : await getLatestBuild(
        process.env.NEXT_PUBLIC_BUILD_ID ||
          process.env.env_var_others_build_id ||
          process.env.env_var_build_id
      );
  const urlArr = filterBuildUrls(
    section || 'others_section',
    level,
    url,
    notMatchText,
    data
  );
  return urlArr || [];
};

export const fetchDetailsIeo = async (region, lang, slug, previewData) => {
  // console.log('fetchDetailsIeo', region, lang, slug);
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const pageData = await new IsoBackendHttpService().get(
      `${API_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          section: 'others',
          slug: `inner-engineering${slug}`,
          region,
          lang,
        },
      }
    );
    return pageData;
  }
};

export const fetchIEOPrgData = async (
  countryId,
  campaign,
  categoryName,
  oldOrNew
) => {
  // console.log('fetchIEOPrgData', countryId);
  const res = await axios.get(
    `${
      oldOrNew === 'old' ? config.IEO_PRG_URL : config.NEW_IEO_PRG_URL
    }?pptCtry=${countryId}&campaign=${campaign || ''}&categoryName=${
      categoryName || ''
    }`
  );
  return res;
};

export const fetchIEOSupportData = async (countryId, lang) => {
  const res = await axios.get(
    `${config.IEO_SPT_URL}?pptCtry=${countryId}&pgmLang=${lang}`
  );
  return res;
};

export const fetchDetailsIeopc = async (region, lang, slug, previewData) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const pageData = await new IsoBackendHttpService().get(
      `${API_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection: 'others',
          slug: `inner-engineering-pc${slug}`,
          region,
          lang,
        },
      }
    );
    return pageData;
  }
};

export const fetchDetailsIeoGift = async (region, lang, slug, previewData) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const pageData = await new IsoBackendHttpService().get(
      `${API_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection: 'others',
          slug: `inner-engineering/gift${slug}`,
          region,
          lang,
        },
      }
    );
    return pageData;
  }
};

export const fetchDetailsIeomigrate = async (
  region,
  lang,
  slug,
  previewData
) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const pageData = await new IsoBackendHttpService().get(
      `${API_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection: 'others',
          slug: `inner-engineering-migration${slug}`,
          region,
          lang,
        },
      }
    );
    return pageData;
  }
};

export const fetchPageData = async (region, lang, slug = ``, previewData) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const pageData = await new IsoBackendHttpService().get(
      `${API_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection: 'others',
          slug: `${slug}`,
          region,
          lang,
        },
      }
    );
    return pageData;
  }
};

//Youth offer
export const initSession = async data => {
  try {
    const res = await axios.post(
      `${config.YOUTH_OFFER_PATH}/register-api/v2.0/ekyc/initialize-session`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    //console.log('response', res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const postRefreshCaptcha = async data => {
  try {
    const res = await axios.post(
      `${config.YOUTH_OFFER_PATH}/register-api/v2.0/ekyc/reload-captcha`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    //console.log('response', res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const generateOTP = async data => {
  let res = await fetch(
    `${config.YOUTH_OFFER_PATH}/register-api/v2.0/ekyc/generate-otp`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  );
  res = await res.json();
  // console.log('response', res);
  return res;
};

export const verifyOtp = async data => {
  // console.log('data', data);

  let res = await fetch(
    `${config.YOUTH_OFFER_PATH}/register-api/v2.0/ekyc/verify-otp`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  );
  res = await res.json();
  // console.log('response', res);
  return res;

  // try {
  //   const res = await axios.post(
  //     `${config.YOUTH_OFFER_PATH}/register-api/v2.0/ekyc/verify-otp`,
  //     data,
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     }
  //   );
  //   console.log('response', res);
  //   return res.data;
  // } catch (err) {
  //   console.log('err', err);
  //   console.log(err);
  // }

  // return {
  //   status: true,
  //   message: 'Success',
  //   data: {
  //     timestamp: '1705581681599',
  //     transaction_id: '16b975394ebe482da179dbf37b7c2f90',
  //     kycId: 'eaa288b97091fc5f9a53f2fc5ee6ab8effeaf16770d7e25a8b136a4061d51822',
  //     name: 'John Doe Jr',
  //     dob: '16-08-1989',
  //     age: 34,
  //     address: {
  //       careOf: 'S/O John Doe',
  //       house: '60A/17',
  //       landmark: 'Kolkata',
  //       locality: '',
  //       pin: '700001',
  //       postOffice: 'Kolkata',
  //       district: 'Kolkata',
  //       subDistrict: '',
  //       state: 'West Bengal',
  //       country: 'India',
  //       street: '',
  //       vtc: 'Kolkata Corporation',
  //     },
  //   },
  // };
};

//Corporate register Coupon
export const fetchIEOCouponApply = async (cCode = '', orgName = '') => {
  if (!cCode || !orgName || cCode === 'undefned' || orgName === 'undefned') {
    return null;
  }
  try {
    const payload = {
      cCode,
      orgName,
    };
    const res = await axios.post(
      `${config.NEXT_PUBLIC_USPRS_API_URL}/organization-coupon/apply`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

// Corporate Enquiry Form

export const postLeadEnquiry = async data => {
  try {
    const response = await axios.post(`${config.PUSH_LEADS}/push_leads`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // console.log('Success:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error:', error.response || error.message);
  }
};

// Recommended for you 7 step dates
export const fetchUpcomingInitiationDates = async (lang, country) => {
  try {
    const res = await axios.get(
      `${config.UPCOMING_INITIATION_DATE}?pgmLang=${lang}&pptCtry=${country}`
    );
    return res.data;
  } catch (error) {
    console.error('Error:', error.response || error.message);
  }
};

export const getStateFromPincode = async (pincode, countryCode) => {
  try {
    let url = `${config.STATE_FROM_PINCODE}/${countryCode}/pincodes/${pincode}`;

    const resp = await axios.get(url);
    const data = resp.data;
    const state = data.state || '';
    const city = data.defaultcity || '';

    if (state !== undefined && city !== undefined) {
      return {
        state: state,
        city: city,
      };
    }
  } catch (error) {
    console.log('Error ', error);
    return {
      state: '',
      city: '',
    };
  }
};
