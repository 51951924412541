import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';
const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    heading: {
      fontSize: {
        base: '34px',
        md: '44px',
        lg: '64px',
      },
      fontFamily: isIndian ? config.langFont[lang] : 'FedraSansStd-medium',
      fontWeight: {
        base: isIndian ? '600' : 'normal',
        md: isIndian ? '600' : 'normal',
      },
      lineHeight: isIndian
        ? {
            base: '42.98px',
            md: '52.68px',
            lg: '72px',
          }
        : {
            base: '32.98px',
            md: '42.68px',
            lg: '62px',
          },
    },
    SubHeading: {
      color: '#E86F34',
      fontSize: {
        base: '20px',
        md: '30px',
        lg: '32px',
      },
      fontFamily: lang ? config.langFont[lang] : fontFamily,
      fontWeight: {
        base: isIndian ? '600' : 'normal',
        md: isIndian ? '600' : 'normal',
      },
      lineHeight: isIndian
        ? {
            base: '42px',
            md: '46px',
            lg: '52.2px',
          }
        : {
            base: '22px',
            md: '33px',
            lg: '35.2px',
          },
    },
    AttendText: {
      fontSize: {
        base: '18px',
        md: '22px',
        lg: '22px',
      },
      fontFamily: lang ? config.langFont[lang] : fontFamily,
      fontWeight: {
        base: isIndian ? '550' : '450',
        md: isIndian ? '550' : '450',
      },
      lineHeight: {
        base: '26.64px',
        md: '32.56px',
        lg: '32.56px',
      },
    },
    stBookText: {
      fontFamily: lang ? config.langFont[lang] : fontFamily,
      fontSize: '18px',
      lineHeight: isIndian ? '32px' : '27px',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const IeoInpersonSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({
    lang: 'ar',
    heading: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    SubHeading: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    AttendText: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    stBookText: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default IeoInpersonSt;
