import { programTypes } from '@ieo/Components/Form/ieo/utils';
import pick from 'lodash/pick';

// import { getLanguageUrlParam } from '../../../../utils/translationLogic'

/*
{
 "users": [
  {
   "fname": "Resa",
   "lname": "Tesa",
   "email": "aakash14445@yopmail.com",
   --"sex": "male",
   --"dob": "1992-10-07",
   "mobile_phone_country_code": "+91",
   "pPhone": "9858457858",
   "home_phone_country_code": "+91",
   "sPhone": "9858457858",
   "address1": "Near here where",
   --"address2": "Near here where",
   "city": "Nagpur",
   "state": "Maharashtra",
   "zip": "440022",
   "country": "IN",
   "cme": "0"
  }
 ],
 "paymentType": "Full",
 "cCode": "",
 "tCost": 4000,
 "pgmCurr": "INR",
 "langPref": "EN",
 "vId": "SGAPP",
 "regType": "New",
 "startStepId": "1"
}
 */

export function formatDataForApiSubmission({
  values,
  cost,
  language,
  pgmCurr,
  regType,
  startStepId = '1',
  isIndia,
  programCountry,
  hiddenccCode,
  kycId,
  parent,
  vId,
  file,
  categoryRegistration,
  categoryName,
  categoryType,
  employeeId,
}) {
  return {
    users: [
      {
        ...pick(values, [
          'fname',
          'lname',
          'email',
          'pPhone',
          'sPhone',
          'address1',
          'city',
          'state',
          'country',
          'zip',
        ]),
        sex: null,
        dob: null,
        address2: null,
        mobile_phone_country_code: values.pCountry,
        home_phone_country_code: values.sCountry,
        cme: '0',
        tnc: isIndia
          ? {
              receiveMessages: values.receiveMessages,
              age: values.age,
              terms: values.terms,
              medical: values.medical,
            }
          : {
              disclaimer: values.disclaimerWaiver,
              liability: values.liabilityWaiver,
              receiveMessages: values.receiveMessages,
              terms: values.terms,
            },
      },
    ],
    langPref: language,
    paymentType: 'Full',
    cCode: values.cCode || hiddenccCode,
    tCost: cost,
    pgmCurr,
    pgmCtry: programCountry,
    vId: vId || 'AEOI',
    regType,
    startStepId,
    kycId,
    parent: parent,
    //...(variant === 'corporate' && { corpUser: 'C' }),
    file,
    categoryRegistration,
    categoryName,
    categoryType,
    employeeId,
  };
}

export function formatDataForGiftingApiSubmission({
  values,
  cost,
  pgmCurr,
  programCountry,
  hiddenccCode,
  vId,
}) {
  return {
    users: [
      {
        ...pick(values, [
          'fname',
          'lname',
          'email',
          'pPhone',
          'sPhone',
          'address1',
          'city',
          'state',
          'country',
          'zip',
        ]),
        address2: null,
        mobile_phone_country_code: values.pCountry,
        home_phone_country_code: values.sCountry,
        tnc: {
          nonrefundable: values.nonrefundable,
          geoRestriction: values.geoRestriction,
          terms: values.terms,
          codeTransfer: values.codeTransfer,
        },
      },
    ],
    cCode: values.cCode || hiddenccCode,
    vId: vId || 'AEOI',
    tCost: cost,
    pgmCurr,
    pgmCtry: programCountry,
  };
}

export function formatDataForNewApiSubmission({
  values,
  cost,
  language,
  pgmCurr,
  regType,
  startStepId = '1',
  isIndia,
  programCountry,
  hiddenccCode,
  kycId,
  parent,
  vId,
  selectedProgram,
  file,
  categoryRegistration,
  categoryName,
  categoryType,
  employeeId,
  checksum,
  nameEmailCheckSum,
  ssoId,
}) {
  console.log(
    'valuesvalues',
    values,
    checksum,
    nameEmailCheckSum,
    selectedProgram
  );
  return {
    vId: vId || 'AEOI',
    pgmId: selectedProgram,
    pgmType: programTypes.IEO,
    pgmCtry: programCountry,
    regType: regType,
    usap: {
      fname: values.fname,
      lname: values.lname,
      email: values.email,
      nameEmailCheckSum: nameEmailCheckSum,
      pPhone: {
        dialCode: values.pCountry,
        nationalNumber: values.pPhone,
      },
      wPhone: {
        dialCode: values.sCountry,
        nationalNumber: values.sPhone,
      },
      sex: null,
      dob: null,
    },
    address: {
      country: values.country,
      state: values.state,
      addr1: values.address1,
      addr2: null,
      city: values.city,
      zip: values.zip,
    },
    tCost: {
      fee: cost,
      currency: pgmCurr,
      cCode: values.cCode || hiddenccCode,
      checksum: checksum,
    },
    tnc: isIndia
      ? {
          receiveMessages: values.receiveMessages,
          age: values.age,
          terms: values.terms,
          medical: values.medical,
        }
      : {
          disclaimer: values.disclaimerWaiver,
          liability: values.liabilityWaiver,
          receiveMessages: values.receiveMessages,
          terms: values.terms,
        },
    file: file,
    categoryRegistration: categoryRegistration,
    categoryName: categoryName,
    categoryType: categoryType,
    employeeId: employeeId,
    kycId: kycId,
    lng: language?.toLowerCase(),
    parent: parent,
    ssoId: ssoId,
  };
}
