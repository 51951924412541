/* Built In Imports */
/* External Imports */
import { Box, Button as ChakraButton } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { EnrollSectionSt } from '@ieo/Common/CompLangConfig';

/* Services */

const lang = 'en';

export const Button = props => (
  <ChakraButton
    bg="#3652B8"
    _hover={{ bg: '#142C80' }}
    px="40px"
    minH="60px"
    py="20px"
    fontWeight="normal"
    color="#fff"
    borderRadius="full"
    {...EnrollSectionSt[lang].button}
    {...props}
  />
);

export function SubmitButton({ label, isSubmitting, testId, ...props }) {
  return (
    <Button
      variant={'contained'}
      color={'white'}
      type={'submit'}
      data-test-id={testId}
      isLoading={isSubmitting}
      {...props}
    >
      {label}
    </Button>
  );
}

export const FormErrorMessage = ({ children }) => (
  <Box fontSize={'12px'} color={'#D50000'} mb={2} textAlign={'center'}>
    {children}
  </Box>
);

/*
"Please enter your first name"
"First name is not valid"
"Please enter your last name"
"Last name is not valid"
"Please enter your phone number"
"Please enter a valid phone number"
"Please enter a valid WhatsApp number"
"Please select your country"
"Please enter your city"
"Please enter your state"
"Please enter your postal code"
"Please enter your postal code. It should be 6 digits."
"Please enter a valid postal code"
"Please enter your address. It should be greater than 15 characters."
"Please agree to the checkbox to proceed."
"Please read and accept the Terms and Conditions for Inner Engineering"
"Error"
"You already have a transaction in progress. Please try again after 30 minutes."
"Something went wrong. Please contact Support."

 */

export const englishCharactersValidation = {
  value: /^[a-zA-Z0-9\s]+$/,
  message: 'validationMessages.englishCharacters',
};

export const blankSpaceValidation = {
  value: /^(?!\s*$)/,
  message: 'validationMessages.blankSpace',
};

export const emailCharactersValidation = {
  value: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,}$/,
  message: 'validationMessages.email.pattern',
};

export const addressCharactersValidation = {
  value: /^[a-zA-Z0-9/\-,.#\s]+$/,
  message: 'validationMessages.address.pattern',
};

export const cityCharactersValidation = {
  value: /^[a-zA-Z\s]+$/,
  message: 'validationMessages.city.pattern',
};

export const couponCodeValidation = {
  value: /^[a-zA-Z0-9\s]+$/,
  message: 'validationMessages.voucherCode.invalid',
};

export const canteenCardCharactersValidation = {
  value: /^[A-Z]{2}[0-9]{14}[A-Z][0-9]{2}$/,
  message: 'validationMessages.canteenCardNumber.pattern',
};
